import numeral from "numeral";

const s3url = process.env.REACT_APP_S3_CONTENT_BASEURL;
export const images = [
  {
    id: 1,
    pic: s3url + "court/mainSlider/court_banner_01.png",
    title: `공간이 필요한 모든 순간\nTaapSPACE로 쉽고 간편하게`,
  },
];

export const defaultParams = {
  page: 0,
  size: 10,
  sort: {
    orders: [
      {
        property: "id",
        direction: "DESC",
      },
    ],
  },
};

export function numberToStringWithComma(num?: number, format: string = "0,0"): string {
  // return (num || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return numeral(num).format(format);
}
