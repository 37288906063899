import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { patchJoinAsync } from "src/api/member/member-api";
import { BaseButton, BaseTextInput, ConfirmModal } from "src/components";
import { useCourtAuth } from "src/components/court-auth";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import AgreeCheckbox from "./components/AgreeCheckbox";
import { useMemberInfoState } from "src/recoil/member/hook";
import { TERMS_OF_SERVICE_URL } from "../constants";

type FormData = {
  email: string;
  isAgreeAll: boolean; // 모두 동의
  isAgreeTermsOfService: boolean; // [필수] 서비스 이용약관 동의
  isAgreePrivacy: boolean; // [필수] 개인정보 수집 및 이용 동의
  isAgreeMarketing: boolean; // [선택] 이벤트/마케팅 수신 동의
  isAgeLimit: boolean; // [필수] 만 14세 이상 동의
};

/**
 * 이메일 등록 화면 (회원가입)
 */
const RegisterEmail = () => {
  const navigate = useNavigate();

  const { refreshTokenAsync } = useCourtAuth();

  // 회원가입 api
  const { executeAsync: patchJoin, loading: patchJoinLoading } = useApiOperation(patchJoinAsync);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const defaultValues = useMemo(() => {
    return {
      email: "",
      isAgreeAll: false,
      isAgreeTermsOfService: false,
      isAgreePrivacy: false,
      isAgreeMarketing: false,
      isAgeLimit: false,
    };
  }, []);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    formState: { isValid, isDirty },
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    register("email", {
      required: "이메일을 입력해주세요",
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "올바르지 않은 이메일 형식입니다",
      },
    });
    register("isAgreeTermsOfService", {
      required: true,
    });
    register("isAgeLimit", {
      required: true,
    });
    register("isAgreePrivacy", {
      required: true,
    });
  }, [register]);

  // 모두 동의하거나, 하나라도 동의안했을 경우 모두동의 체크박스 변경
  const [isAgreeMarketing, isAgreePrivacy, isAgreeTermsOfService, isAgeLimit] = watch([
    "isAgreeMarketing",
    "isAgreePrivacy",
    "isAgreeTermsOfService",
    "isAgeLimit",
  ]);
  useEffect(() => {
    if (isAgreeMarketing && isAgreePrivacy && isAgreeTermsOfService && isAgeLimit) {
      setValue("isAgreeAll", true, { shouldValidate: true });
    } else {
      setValue("isAgreeAll", false, { shouldValidate: true });
    }
  }, [isAgreeMarketing, isAgreePrivacy, isAgreeTermsOfService, isAgeLimit]);

  // 회원가입
  const join = useCallback(
    async (data: FormData) => {
      const { data: patchJoinResultData } = await patchJoin({ email: data.email, marketingConsent: data.isAgreeMarketing });
      if (patchJoinResultData?.data?.member) {
        // 회원가입 후 refresh token
        await refreshTokenAsync();
        // 회원가입 완료 페이지로 이동
        navigate("/court/join/complete");
      }
    },
    [patchJoin, refreshTokenAsync, navigate],
  );

  // validation 통과 후 submit 될때 실행
  const onSubmit = useCallback(
    (data: FormData, e?: any) => {
      e.preventDefault();

      console.log("onSubmit", data, e);

      // 회원가입
      join(data);
    },
    [join],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  };

  return (
    <>
      <Header rightDisabled={true} />
      <div className="my-page">
        <MetaTag title="회원가입" />

        <article className="register">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="title-wrap">
              <h1>이메일을 등록해주세요</h1>
              <p>
                안전한 계정관리를 위해 이메일을 등록해주세요!
                <br /> 휴대폰 번호 변경 등 계정에 변동 사항이 있을 때 사용할 수 있어요.
              </p>
              <span>*기업 회원인 경우 기업 이메일을 등록해주세요.</span>
            </div>
            <Controller
              control={control}
              name="email"
              render={({ field: { name, value, onChange }, fieldState: { error, isDirty } }) => (
                <BaseTextInput
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder="이메일을 입력해주세요"
                  isInvalid={!!error}
                  invalidMessage={error?.message}
                  isCompleted={isDirty && !error}
                />
              )}
            ></Controller>

            {/* 약관동의 */}
            <section className="my80">
              <div className="mb20">
                <Controller
                  control={control}
                  name="isAgreeAll"
                  render={({ field: { name, value } }) => (
                    <>
                      <input
                        className="checkbox"
                        type="checkbox"
                        id={name}
                        name={name}
                        checked={value}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setValue("isAgreeAll", checked, { shouldValidate: true });
                          setValue("isAgreeTermsOfService", checked, { shouldValidate: true });
                          setValue("isAgreePrivacy", checked, { shouldValidate: true });
                          setValue("isAgreeMarketing", checked, { shouldValidate: true });
                          setValue("isAgeLimit", checked, { shouldValidate: true });
                        }}
                      />
                      <label htmlFor="isAgreeAll" className="font16 flex-center">
                        <div className="base-checkbox"></div>
                        <p className="ml10">
                          <b>아래의 약관에 모두 동의합니다.</b>
                        </p>
                      </label>
                    </>
                  )}
                ></Controller>
              </div>
              <div className="mb10">
                <Controller
                  control={control}
                  name="isAgreeTermsOfService"
                  render={({ field: { name, value, onChange } }) => (
                    <AgreeCheckbox
                      id={name}
                      checked={value}
                      onChange={onChange}
                      label="[필수]"
                      underlineLabel="서비스 이용약관 동의"
                      url={TERMS_OF_SERVICE_URL}
                    />
                  )}
                ></Controller>
              </div>
              <div className="mb10">
                <Controller
                  control={control}
                  name="isAgreePrivacy"
                  render={({ field: { name, value, onChange } }) => (
                    <AgreeCheckbox
                      id={name}
                      checked={value}
                      onChange={onChange}
                      label="[필수]"
                      underlineLabel="개인정보 수집 및 이용 동의"
                      textDecorationNone
                    />
                  )}
                ></Controller>
              </div>
              <div className="mb10">
                <Controller
                  control={control}
                  name="isAgeLimit"
                  render={({ field: { name, value, onChange } }) => (
                    <AgreeCheckbox
                      id={name}
                      checked={value}
                      onChange={onChange}
                      label="[필수]"
                      underlineLabel="본인은 만 14세 이상입니다"
                      textDecorationNone
                    />
                  )}
                ></Controller>
              </div>
              <div className="mb10">
                <Controller
                  control={control}
                  name="isAgreeMarketing"
                  render={({ field: { name, value, onChange } }) => (
                    <AgreeCheckbox
                      id={name}
                      checked={value}
                      onChange={onChange}
                      label="[선택]"
                      underlineLabel="이벤트/마케팅 수신 동의"
                      textDecorationNone
                    />
                  )}
                ></Controller>
              </div>
            </section>
            <BaseButton type="submit" className="login-btn" disabled={!isDirty || !isValid} title="다음" isLoading={patchJoinLoading} />
          </form>
          <ConfirmModal
            isOpen={isModalOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={onModalClose}
            children={<p className="font18">가입을 취소할까요?</p>}
          />
        </article>
      </div>
    </>
  );
};
export default RegisterEmail;
