import React, { useState, useEffect, useRef, ReactNode } from "react";
import { createPortal } from "react-dom";
import { tooltipContainerState, TooltipContainer } from "../../recoil/tooltip/atom";
import { useRecoilValue } from "recoil";

type Props = {
  children: ReactNode;
  position: "right" | "left";
  title?: string;
};
const Tooltip = ({ children, position = "right", title }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const fadeTimeoutRef = useRef<NodeJS.Timeout>();
  const triggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { element: container } = useRecoilValue<TooltipContainer>(tooltipContainerState);

  const handleClose = () => {
    setIsFading(true);
    fadeTimeoutRef.current = setTimeout(() => {
      setIsFading(false);
      setIsOpen(false);
    }, 1000);
  };

  const handleOpen = () => {
    if (fadeTimeoutRef.current) {
      clearTimeout(fadeTimeoutRef.current);
    }
    setIsFading(false);
    setIsOpen(true);
  };

  useEffect(() => {
    const updatePosition = () => {
      if (!triggerRef.current || !tooltipRef.current || !container) return;

      const triggerRect = triggerRef?.current?.getBoundingClientRect();
      const tooltipRect = tooltipRef?.current?.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      // 아이콘 아래쪽 위치로
      const top = triggerRect.top - containerRect.top + triggerRect.height;
      let left;

      if (position === "right") {
        // 컨테이너의 오른쪽에서 24px 여백 + 툴팁 너비
        left = containerRect.width - tooltipRect.width - 24;
      } else {
        // 컨테이너의 왼쪽에서 24px 여백
        left = 24;
      }

      setTooltipPosition({ top, left });
    };

    if (isOpen) {
      updatePosition();
      window.addEventListener("resize", updatePosition);
      return () => window.removeEventListener("resize", updatePosition);
    }
  }, [isOpen, position, container]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!event.target || !tooltipRef.current || !triggerRef.current) return;

      const target = event.target as Node;

      if (!tooltipRef.current.contains(target) && !triggerRef.current.contains(target)) {
        setIsOpen(false);
      }
    };

    if (isOpen && isMobile) {
      document.addEventListener("click", handleClickOutside);
      return () => document.removeEventListener("click", handleClickOutside);
    }
  }, [isOpen, isMobile]);

  useEffect(() => {
    return () => {
      if (fadeTimeoutRef.current) {
        clearTimeout(fadeTimeoutRef.current);
      }
    };
  }, []);

  const handleTriggerInteraction = () => {
    if (isMobile) {
      isOpen ? handleClose() : handleOpen();
    }
  };
  const tooltipContent = (
    <div
      ref={tooltipRef}
      className={`tooltip-content ${isFading ? "tooltip-fade-out" : ""}`}
      style={{
        top: `${tooltipPosition.top}px`,
        left: `${tooltipPosition.left}px`,
      }}
      onMouseEnter={() => !isMobile && handleOpen()}
      onMouseLeave={() => !isMobile && handleClose()}
    >
      {title && <h3 className="mb10">{title}</h3>}
      {children}
    </div>
  );

  if (!container) return null;

  return (
    <>
      <div
        ref={triggerRef}
        className="tooltip-trigger"
        onClick={handleTriggerInteraction}
        onMouseEnter={() => !isMobile && handleOpen()}
        onMouseLeave={() => !isMobile && handleClose()}
      >
        <div className="tooltip-icon"></div>
      </div>
      {(isOpen || isFading) && createPortal(tooltipContent, container)}
    </>
  );
};

export default Tooltip;
