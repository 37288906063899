import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ContractModel, Quotation, ScheduleRecentBill } from "src/api/contract/contract-types";
import { makeCommonSpaceList, numberToStringWithComma, onlyNumber, validatePhoneNumberBoolean } from "src/utils/common-util";
import ServiceTypeGuide from "./ServiceTypeGuide";
import ServiceTypePaymentMethodSelector from "./ServiceTypePaymentMethodSelector";
import { BaseCheckbox } from "src/components/BaseCheckbox";
import BaseAbstractModal from "src/components/BaseAbstractModal";
import { useApiOperation } from "src/api/hooks";
import { contractsProviderData, getPaymentReceiptAsync, getQuotationList, getRefundInfo } from "src/api/contract/contract-api";
import _ from "lodash";
import { useToast } from "src/pages/hooks/toast";
import { BaseSelect, BaseTextInput } from "src/components";
import BaseFullModal from "src/components/BaseFullModal";
import TermsOfPolicy from "./guide/TermsOfPolicy";
import TermsOfThirdParty from "./guide/TermsOfThirdParty";

type Props = {
  contract: ContractModel;
  recentBill: ScheduleRecentBill;
  paymentStatus: any;
  primaryQuotationName?: any;
  documentProofRequest: any;
  setDocumentProofRequest: any;
  billingContractBill: any;
  handleClickPaymentS2: (pgcode: string, documentProofRequest: any) => void;
  handleClickExpenseProofCashBill: (documentProofRequest: any) => void;
  proofError: any;
  setProofError: any;
};
const ServiceTypePaymentInfo = ({
  contract,
  paymentStatus,
  recentBill,
  primaryQuotationName,
  handleClickPaymentS2,
  documentProofRequest,
  setDocumentProofRequest,
  billingContractBill,
  handleClickExpenseProofCashBill,
  proofError,
  setProofError,
}: Props) => {
  const [fullPop1, setFullPop1] = useState(false);
  const [fullPop2, setFullPop2] = useState(false);
  const [cashErrorTextFlag1, setCashErrorTextFlag1] = useState(false);
  const [cashErrorTextFlag2, setCashErrorTextFlag2] = useState(false);
  const [cashErrorTextFlag3, setCashErrorTextFlag3] = useState(false);
  const { openToast } = useToast();
  // 계약 견적서 조회 api
  const { executeAsync: patchContractQuotations } = useApiOperation(getQuotationList);
  const { executeAsync: findSnapshot } = useApiOperation(getRefundInfo);
  const { executeAsync: findProviderData } = useApiOperation(contractsProviderData);
  const { executeAsync: getPaymentReceipt } = useApiOperation(getPaymentReceiptAsync);
  
  // 거래내역 확인서
  const fetchPaymentReciptUrl = async (payment: any) => {
    const { data } = await getPaymentReceipt({ id: String(payment.paymentId) });
    const url = data.data.receipt_url;

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "openInAppBrowser",
          data: {
            event: url,
          },
        }),
      );
    } else {
      window.open(url);
      // window.location.href = url;
    }
  };
  //S2 결제 수단 선택 대상 적용 - 기본값 카드 결제
  const [pgcode, setPgcode] = useState<string>(recentBill.pgcode);
  const [phoneNumber, setPhoneNumber] = useState<string>("050-3220-6515");
  const [paymentAgree, setPaymentAgree] = useState(false);
  const [policyAgree, setPolicyAgree] = useState(false);
  const [thirdAgree, setThirdAgree] = useState(false);
  const [isAsk, setIsAsk] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [quotation, setQuotation] = useState<any>({});
  const [receiptBundle, setReceiptBundle] = useState<any>({});
  const [cashBillList, setCashBillList] = useState<any>([]);
  const [taxInvoiceList, setTaxInvoiceList] = useState<any>([]);
  const [refundInfo, setRefundInfo] = useState<any>(null);
  const [guideInfo, setGuideInfo] = useState<any>(null);
//계약 상품의 환불정보 조회
  const fetchFindInfo = useCallback(async (contractId: string) =>{
    const _guideInfo = await findSnapshot({contractId: contractId, domain: 'product', attrName:'productNotice'} as any);
    if(_guideInfo.status === 200 && _guideInfo.data.data && _guideInfo.data.data.content&& _guideInfo.data.data.content.length > 0){
      setGuideInfo(_guideInfo.data.data.content[0])
    }
    const _refundInfo = await findSnapshot({contractId: contractId, domain: 'product', attrName:'refundInfo'} as any);
    if(_refundInfo.status === 200 && _refundInfo.data.data && _refundInfo.data.data.content&& _refundInfo.data.data.content.length > 0){
      setRefundInfo(_refundInfo.data.data.content[0])
    }
  },[findSnapshot])
  // 계약 견적서 조회
  const contractQuotationList = useCallback(
    async (quotationList: any) => {
      // console.log("quotationList", quotationList);
      //S2 계약의 견적서 id 정리
      let ids: string = "";
      let orderNumSorted = _.sortBy(quotationList, ["orderNum"]).reverse();

      orderNumSorted!.forEach((list: Quotation) => {
        if (ids !== "") {
          ids = ids + "," + list.quotationNo;
        } else {
          ids = list.quotationNo;
        }
      });

      const response: any = await patchContractQuotations({ quotationNo: ids });

      setQuotation(response.data.data.content[0].quotationItemList[0]);
      //견적서의 공용공간으로 건물의 공용공간 중 매핑되는 것을 처리
      // console.log("response", response.data.data.content[0].quotationItemList[0]);
      //
    },
    [patchContractQuotations],
  );

  // const [privateAgree, setPrivateAgree] = useState(false);
  // const [thirdPersonAgree, setThirdPersonAgree] = useState(false);
  // console.log("paymentStatus", paymentStatus, recentBill);

  const checkExpireText = (virtualAccount: any) => {
    let text = "";
    if (virtualAccount !== null) {
      const today = moment();
      let limit = virtualAccount.expireDate + " " + virtualAccount.expireTime;
      const limitDate = moment(limit, "YYYYMMDD HHmm");
      text = moment(limit, "YYYYMMDD HHmm").format("YYYY-MM-DD HH:mm");
      if (moment(today).isSameOrAfter(limitDate)) {
        text = "만료";
      }
    }
    return text;
  };
  const checkExpireFiltered = useMemo(() => {
    const today = moment();
    let showArr: any = [];

    if (paymentStatus.length === 0 && recentBill.billPayStatus === "PAYMENT_READY") {
      paymentStatus.forEach((status: any) => {
        if (status.virtualAccount !== null) {
          let limit = status.virtualAccount.expireDate + " " + status.virtualAccount.expireTime;
          const limitDate = moment(limit, "YYYYMMDD HHmm");
          if (moment(today).isSameOrBefore(limitDate)) {
            showArr.push(status);
          }
        }
      });
    } else {
      showArr = paymentStatus;
    }

    return showArr;
  }, [paymentStatus, recentBill]);
  // 부가세
  const vat = useMemo(() => {
    let _vat;
    const supplyPrice = quotation?.price;

    _vat = supplyPrice && (supplyPrice * 1.1) / 11;
    return Math.round(Number(_vat));
  }, [quotation]);
  const findProviderCs = useCallback(
    async (contractId: string) => {
      // TODO
      const csData: any = await findProviderData({ contractId, assignedWorkType: "CS_PROVIDE" });

      // console.log("csData", csData.data.data.content);
      if (csData.data.data.content.length > 0) {
        setPhoneNumber(csData.data.data.content[0].contact!);
      }
    },
    [findProviderData],
  );
  const handleCopyClipBoard = async (bankAccount: string) => {
    const text = `${bankAccount}`;
    try {
      await navigator.clipboard.writeText(text);
      openToast({ content: "계좌번호가 복사되었습니다" });
    } catch (err) {
      console.log(err);
      openToast({ content: "문제가 발생했습니다" });
    }
  };

  useEffect(() => {
    contractQuotationList(contract.quotationList);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // const isMobile = true;
    if (isMobile) {
      // mobile
      setIsMobile(true);
    } else {
      // desktop
      setIsMobile(false);
    }
    if (contract.contractId) {
      findProviderCs(String(contract.contractId));
      fetchFindInfo(String(contract.contractId));
    }
    // console.log("billingContractBill", billingContractBill);
    if (billingContractBill) {
      const bundle = billingContractBill.billList[0].originBill.receiptBundle;
      setReceiptBundle(bundle || null);

      setCashBillList(billingContractBill.billList[0].originBill.cashbillList || []);
      setTaxInvoiceList(billingContractBill.billList[0].originBill.taxInvoiceList || []);
    }
  }, [contract, findProviderCs, billingContractBill, setReceiptBundle, contractQuotationList, fetchFindInfo]);

  const checkProgressStatus = (progressStatus: string) => {
    let status = "";
    if (progressStatus === "TEMP_SAVE") {
      status = "임시저장";
    }
    if (progressStatus === "ISSUE_COMPLETE") {
      status = "발행완료";
    }
    if (progressStatus === "ISSUE_REVOKE") {
      status = "발행취소";
    }
    if (progressStatus === "TRANS_BEFORE") {
      status = "전송전";
    }
    if (progressStatus === "TRANS_READY") {
      status = "전송대기";
    }
    if (progressStatus === "TRANS_ONGOING") {
      status = "전송중";
    }
    if (progressStatus === "TRANS_SUCCESS") {
      status = "전송성공";
    }
    if (progressStatus === "TRANS_FAIL") {
      status = "전송실패";
    }
    if (progressStatus === "ISSUE_MANUAL") {
      status = "수기발행";
    }
    return status;
  };
  return (
    <>
      <section className="base-section-layout top-divider">
        <div className="base-section-layout__container">
          <div className="base-section-layout__wrap mb30 pb0">
            <div className="base-section-layout__title-wrap">
              <h2>{recentBill.pgcode === "creditcard" ? "결제 정보" : "입금 정보"}</h2>
            </div>
            {checkExpireFiltered.length === 0 && (
              <>
                <div className="base-section-layout-line">
                  <p className="base-section-layout-index text-primary3">상품금액</p>
                  <p className="base-section-layout-value">{numberToStringWithComma(Number(recentBill?.supplyAmount)) || 0} 원</p>
                </div>
                <div className="base-section-layout-line">
                  <p className="base-section-layout-index text-primary3">부가세</p>
                  <p className="base-section-layout-value">{numberToStringWithComma(Number(recentBill?.taxAmount || 0))} 원</p>
                </div>
                <div className="base-section-layout-line">
                  <p className="base-section-layout-index text-primary3">최종 결제금액</p>
                  <span>
                    <span className="base-section-layout-value totalPrice font18">
                      {numberToStringWithComma(Number(recentBill?.totalAmount || 0))}
                    </span>
                    <span className="font14"> 원</span>
                  </span>
                </div>
              </>
            )}
            {checkExpireFiltered.map((payment: any, index: number) => {
              return (
                <div key={index} className="mb20">
                  <div className="base-section-layout-line">
                    <p className="base-section-layout-index text-primary3">상품금액</p>
                    <p className="base-section-layout-value">{numberToStringWithComma(Number(payment?.totalAmount - payment?.taxAmount)) || 0} 원</p>
                  </div>
                  <div className="base-section-layout-line">
                    <p className="base-section-layout-index text-primary3">부가세</p>
                    <p className="base-section-layout-value">{numberToStringWithComma(Number(payment?.taxAmount || 0))} 원</p>
                  </div>

                  {payment?.pgcode === "creditcard" && (
                    <>
                      <div className="base-section-layout-line">
                        <p className="base-section-layout-index text-primary3">총 결제금액</p>
                        <span>
                          <span className="base-section-layout-value totalPrice font18">
                            {numberToStringWithComma(Number(payment?.totalAmount || 0))}
                          </span>
                          <span className="font14"> 원</span>
                        </span>
                      </div>
                      <div className="horizontal-divider"></div>
                      <div className="base-section-layout-line">
                        <p className="base-section-layout-index text-primary3">결제수단</p>
                        <p className="base-section-layout-value">{"카드결제"}</p>
                      </div>
                      <div className="base-section-layout-line">
                        <p className="base-section-layout-index text-primary3">카드번호</p>
                        <p className="base-section-layout-value">{payment?.cardInfo}</p>
                      </div>
                      {payment?.paymentCancel !== null ? (
                        <>
                          <div className="base-section-layout-line">
                            <p className="base-section-layout-index text-primary3">취소일시</p>
                            <p className="base-section-layout-value">{moment(payment?.paymentCancel.cancelDate).format("YYYY-MM-DD HH:mm")}</p>
                          </div>
                          <div className="base-section-layout-line">
                            <p className="base-section-layout-index text-primary3">취소금액</p>
                            <p className="base-section-layout-value">{numberToStringWithComma(payment?.paymentCancel.cancelAmount) + " 원"}</p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="base-section-layout-line">
                            <p className="base-section-layout-index text-primary3">결제일시</p>

                            {receiptBundle?.lastTrDateTime && (
                              <p className="base-section-layout-value">{moment(receiptBundle?.lastTrDateTime).format("YYYY-MM-DD HH:mm")}</p>
                            )}
                          </div>
                        </>
                      )}
                      <button
                        className="mt20 base-btn color-white card_payment_receipt"
                        onClick={() => {
                          fetchPaymentReciptUrl(payment);
                        }}
                      >
                        카드 영수증 보기
                      </button>
                    </>
                  )}
                  {payment?.pgcode === "virtualaccount" && (
                    <>
                      <div className="base-section-layout-line">
                        {recentBill.billPayStatus === "PAYMENT_SUCCESS" ? (
                          <p className="base-section-layout-index text-primary3">입금금액</p>
                        ) : (
                          <p className="base-section-layout-index text-primary3">입금 대기 금액</p>
                        )}

                        <span>
                          <span className="base-section-layout-value totalPrice font18">
                            {numberToStringWithComma(Number(payment?.totalAmount || 0))}
                          </span>
                          <span className="font14"> 원</span>
                        </span>
                      </div>
                      <div className="horizontal-divider"></div>
                      <div className="base-section-layout-line">
                        <p className="base-section-layout-index text-primary3">입금은행</p>
                        <p className="base-section-layout-value">{payment?.virtualAccount?.bankName}(가상계좌)</p>
                      </div>
                      {recentBill.billPayStatus !== "PAYMENT_SUCCESS" && (
                        <div className="base-section-layout-line">
                          <p className="base-section-layout-index text-primary3">입금계좌</p>
                          <p
                            className="base-section-layout-value  text-underline cursor-pointer"
                            onClick={() => handleCopyClipBoard(payment?.virtualAccount?.accountNo)}
                          >
                            {payment?.virtualAccount?.accountNo}
                          </p>
                        </div>
                      )}
                      {recentBill.billPayStatus === "PAYMENT_READY" ||
                        (recentBill.billPayStatus === "PAYMENT_FREE" && (
                          <>
                            <div className="base-section-layout-line">
                              <p className="base-section-layout-index text-primary3">입금기한</p>
                              <p className="base-section-layout-value text-red">{checkExpireText(payment?.virtualAccount)}</p>
                            </div>
                            <div className="base-section-layout-line">
                              <p className="base-section-layout-index text-primary3"></p>
                              <p className="base-section-layout-value">{"기한 내 입금하지 않으면 자동취소 됩니다"}</p>
                            </div>
                          </>
                        ))}
                      {recentBill.billPayStatus === "PAYMENT_SUCCESS" && (
                        <div className="base-section-layout-line">
                          <p className="base-section-layout-index text-primary3">결제일시</p>

                          {receiptBundle?.lastTrDateTime && (
                            <p className="base-section-layout-value">{moment(receiptBundle?.lastTrDateTime).format("YYYY-MM-DD HH:mm")}</p>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      
      <ServiceTypeGuide guideInfo={guideInfo} refundInfo={refundInfo} />

      {contract.contractStep !== "APPLY_CANCEL" &&
        checkExpireFiltered.length > 0 &&
        recentBill?.pgcode === "virtualaccount" &&
        documentProofRequest.expenseProofType === "CASHBILL" && (
          <>
            <section className="base-section-layout top-divider my-page-cashbill">
              <div className="base-section-layout__container">
                <div className="base-section-layout__wrap mb30 pb0">
                  <div className="base-section-layout__title-wrap">
                    <h2>현금영수증</h2>
                  </div>
                  {recentBill.billPayStatus === "PAYMENT_READY" && (
                    <div className="base-section-layout-line">
                      <p className="base-section-layout-index text-primary3">발행</p>
                      <p className="base-section-layout-value">입금완료 시 직접발행</p>
                    </div>
                  )}
                  {recentBill.billPayStatus === "PAYMENT_SUCCESS" &&
                    (cashBillList.length === 0 || cashBillList[0].progressStatus === "TRANS_FAIL" ? (
                      <>
                        <div className="container">
                          <BaseSelect
                            className="identity-type"
                            value={documentProofRequest.identityType}
                            options={
                              [
                                { label: "개인소득공제(휴대폰)", value: "MOBILE_NUM", type: "INCOME" },
                                { label: "개인소득공제(현금영수증카드)", value: "CARD_NUM", type: "INCOME" },
                                { label: "사업자증빙용(사업자번호)", value: "CORP_NUM", type: "EXPENSE" },
                                { label: "사업자증빙용(현금영수증카드) ", value: "CARD_NUM", type: "EXPENSE" },
                              ] as any
                            }
                            getObj={true}
                            onChange={(option: string | number | any) => {
                              const temp = _.cloneDeep(documentProofRequest);
                              temp.identityType = option.value;
                              temp.tradeUsage = option.type;
                              setDocumentProofRequest(temp);
                            }}
                            placeholder="신청 유형을 입력해 주세요 (필수)"
                          />
                          {proofError.error.identityType && <p className="mt5 font12 text-red">신청 유형은 필수입니다.</p>}
                        </div>
                        <div className="container">
                          <BaseTextInput
                            value={documentProofRequest.identityNum}
                            onChange={(value: string | undefined) => {
                              const temp = _.cloneDeep(documentProofRequest);
                              temp.identityNum = onlyNumber(String(value));
                              setDocumentProofRequest(temp);
                              if (value) {
                                setCashErrorTextFlag1(false);
                                setCashErrorTextFlag2(false);
                                setCashErrorTextFlag3(false);
                              }
                            }}
                            onBlur={() => {
                              const temp = _.cloneDeep(documentProofRequest);
                              if (temp.identityType === "MOBILE_NUM" && (temp.identityNum === "" || !validatePhoneNumberBoolean(temp.identityNum))) {
                                setCashErrorTextFlag1(true);
                                setCashErrorTextFlag2(false);
                                setCashErrorTextFlag3(false);
                                temp.identityNum = "";
                              }
                              if (
                                temp.identityType === "CARD_NUM" &&
                                (temp.identityNum === "" || temp.identityNum.length < 13 || temp.identityNum.length > 19)
                              ) {
                                setCashErrorTextFlag1(false);
                                setCashErrorTextFlag2(true);
                                setCashErrorTextFlag3(false);
                                temp.identityNum = "";
                              }
                              if (temp.identityType === "CORP_NUM" && (temp.identityNum === "" || temp.identityNum.length !== 10)) {
                                setCashErrorTextFlag1(false);
                                setCashErrorTextFlag2(false);
                                setCashErrorTextFlag3(true);
                                temp.identityNum = "";
                              }
                              setDocumentProofRequest(temp);
                            }}
                            placeholder="숫자만 입력해 주세요 (필수)"
                          />
                          {cashErrorTextFlag1 && <p className="mt5 font12 text-red">휴대폰 번호 형식이 아닙니다..</p>}
                          {cashErrorTextFlag2 && <p className="mt5 font12 text-red">카드 번호 형식이 아닙니다..</p>}
                          {cashErrorTextFlag3 && <p className="mt5 font12 text-red">사업자 등록번호 형식이 아닙니다.</p>}
                        </div>
                      </>
                    ) : (
                      <div className="base-section-layout-line">
                        <p className="base-section-layout-index text-primary3">발행</p>
                        {cashBillList.map((cash: any, index: number) => {
                          return (
                            <p className="base-section-layout-value" key={index}>
                              {checkProgressStatus(cash.progressStatus)}
                            </p>
                          );
                        })}
                      </div>
                    ))}
                </div>
              </div>
            </section>
            

            <section className="base-section-layout top-divider">
              {recentBill.billPayStatus === "PAYMENT_SUCCESS" && cashBillList.length === 0 && (
                <>
                  <div className="flex-center my-page-border mb5">
                    <BaseCheckbox
                      id={"paymentAgree"}
                      name={"paymentAgree"}
                      checked={paymentAgree}
                      className="ml15 flex-center"
                      onChange={() => {
                        setPaymentAgree(!paymentAgree);
                      }}
                    >
                      <p className="ml4 font14 font-weight-600">
                        <span className="mr4 font13 text-primary4 font-weight-400">[필수]</span>현금영수증 발행 정보를 확인하였습니다.
                      </p>
                    </BaseCheckbox>
                  </div>
                  <div className="base-floating-btn-wrap px0">
                    <button
                      type="button"
                      className="base-btn "
                      // disabled={!paymentAgree || !privateAgree || !thirdPersonAgree}
                      disabled={
                        !paymentAgree ||
                        documentProofRequest.identityType === "" ||
                        documentProofRequest.identityType === null ||
                        documentProofRequest.identityNum === null ||
                        documentProofRequest.identityNum === ""
                      }
                      onClick={() => {
                        const temp = _.cloneDeep(documentProofRequest);
                        let flag = true;
                        if (temp.identityType === "MOBILE_NUM" && (temp.identityNum === "" || !validatePhoneNumberBoolean(temp.identityNum))) {
                          setCashErrorTextFlag1(true);
                          setCashErrorTextFlag2(false);
                          setCashErrorTextFlag3(false);
                          flag = false;
                          temp.identityNum = "";
                          return;
                        }
                        if (
                          temp.identityType === "CARD_NUM" &&
                          (temp.identityNum === "" || temp.identityNum.length < 13 || temp.identityNum.length > 19)
                        ) {
                          flag = false;
                          setCashErrorTextFlag1(false);
                          setCashErrorTextFlag2(true);
                          setCashErrorTextFlag3(false);
                          temp.identityNum = "";
                          return;
                        }
                        if (temp.identityType === "CORP_NUM" && (temp.identityNum === "" || temp.identityNum.length !== 10)) {
                          flag = false;
                          setCashErrorTextFlag1(false);
                          setCashErrorTextFlag2(false);
                          setCashErrorTextFlag3(true);
                          temp.identityNum = "";
                          return;
                        }
                        setDocumentProofRequest(temp);

                        if (flag) {
                          handleClickExpenseProofCashBill(documentProofRequest);
                        }
                      }}
                    >
                      발행
                    </button>
                  </div>
                </>
              )}
            </section>
          </>
        )}
      {contract.contractStep !== "APPLY_CANCEL" &&
        checkExpireFiltered.length > 0 &&
        recentBill?.pgcode === "virtualaccount" &&
        documentProofRequest.expenseProofType !== "CASHBILL" && (
          <>
            <section className="base-section-layout top-divider">
              <div className="base-section-layout__container">
                <div className="base-section-layout__wrap mb30 pb0">
                  <div className="base-section-layout__title-wrap">
                    <h2>세금계산서</h2>
                  </div>

                  {taxInvoiceList.length === 0 && (
                    <div className="base-section-layout-line">
                      <p className="base-section-layout-index text-primary3">발행</p>
                      <p className="base-section-layout-value">발행 대기</p>
                    </div>
                  )}
                  {taxInvoiceList.length > 0 && (
                    <div className="base-section-layout-line">
                      <p className="base-section-layout-index text-primary3">발행</p>
                      {taxInvoiceList.map((tax: any, index: number) => {
                        console.log("tax", tax);
                        return (
                          <p className="base-section-layout-value" key={index}>
                            {checkProgressStatus(tax.progressStatus)}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </section>
            
          </>
        )}
      
      {recentBill?.billPayStatus === "PAYMENT_READY" &&
        checkExpireFiltered.length === 0 &&
        ["CONTRACT_ACCEPT", "USE_APPROVAL"].includes(contract?.contractStep!) &&
        recentBill?.isVerifiedPaymentKey === true && (
          <ServiceTypePaymentMethodSelector
            recentBill={recentBill}
            currentPgCode={pgcode}
            newPgCode={setPgcode}
            documentProofRequest={documentProofRequest}
            setDocumentProofRequest={setDocumentProofRequest}
            proofError={proofError}
            setProofError={setProofError}
          />
        )}
      {checkExpireFiltered.length === 0 &&
      recentBill?.billPayStatus === "PAYMENT_READY" &&
      ["CONTRACT_ACCEPT", "USE_APPROVAL"].includes(contract?.contractStep!) ? (
        <section className={`base-section-layout ${recentBill?.isVerifiedPaymentKey === true ? "top-divider" : ""}`}>
          {recentBill?.isVerifiedPaymentKey === true ? (
            <>
              <div className="flex-center my-page-border-no-line my-page-border-top mb5">
                <BaseCheckbox
                  id={"paymentAgree"}
                  name={"paymentAgree"}
                  checked={paymentAgree}
                  className="ml15 flex-center"
                  onChange={() => {
                    setPaymentAgree(!paymentAgree);
                  }}
                >
                  <p className="ml4 font14 font-weight-600">
                    <span className="mr4 font13 text-primary4 font-weight-400">[필수]</span>내용확인 및 결제 진행 동의
                  </p>
                </BaseCheckbox>
              </div>
              <div className="flex-center-between my-page-border-no-line my-page-border-top mb5">
                <BaseCheckbox
                  id={"policy"}
                  name={"policy"}
                  checked={policyAgree}
                  className="ml15 flex-center"
                  onChange={() => {
                    setPolicyAgree(!policyAgree);
                  }}
                >
                  <div className="flex-center-between ml4 font14 font-weight-600">
                    <span className="mr4 font13 text-primary4 font-weight-400">[필수]</span>개인정보 제3자 동의
                  </div>
                </BaseCheckbox>
                <div className="arrow-right" onClick={() => setFullPop1(true)}></div>
                {fullPop1 && <BaseFullModal children={<TermsOfThirdParty onClose={() => setFullPop1(false)} />} isOpen={fullPop1} />}
              </div>
              <div className="flex-center-between my-page-border-no-line my-page-border-top mb5">
                <BaseCheckbox
                  id={"thirdAgree"}
                  name={"thirdAgree"}
                  checked={thirdAgree}
                  className="ml15 flex-center"
                  onChange={() => {
                    setThirdAgree(!thirdAgree);
                  }}
                >
                  <p className="ml4 font14 font-weight-600">
                    <span className="mr4 font13 text-primary4 font-weight-400">[필수]</span>개인정보 수집 및 이용 동의
                  </p>
                </BaseCheckbox>
                <div className="arrow-right" onClick={() => setFullPop2(true)}></div>
                {fullPop2 && <BaseFullModal children={<TermsOfPolicy onClose={() => setFullPop2(false)} />} isOpen={fullPop2} />}
              </div>

              <div className="base-floating-btn-wrap px0">
                <button
                  type="button"
                  className="base-btn "
                  // disabled={!paymentAgree || !privateAgree || !thirdPersonAgree}
                  disabled={!paymentAgree || !policyAgree || !thirdAgree}
                  onClick={() => handleClickPaymentS2(pgcode, documentProofRequest)}
                >
                  결제하기
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="base-floting-btn-wrap px0">
                <div className="center">
                  <div className="flex-center-start mb10">
                    <div className="red-bubble"></div>
                    <span className="system-red font15 ml5">해당 예약은 현재 결제가 불가능합니다.</span>
                  </div>
                  <p className="text-gray  font14">결제 관련 도움이 필요하시면 아래 버튼을 눌러주세요</p>
                  <p className="text-gray  font14 mb10">탭 스페이스 결제 관련이라고 말씀하시면 보다 빠르게 도움 드리도록 하겠습니다.</p>
                </div>
                {isMobile ? (
                  <a href={"tel:" + phoneNumber} className="base-btn mt30">
                    전화하기
                  </a>
                ) : (
                  <button type="button" className="base-btn mt30" onClick={() => setIsAsk(true)}>
                    문의하기
                  </button>
                )}
                {isAsk && (
                  <BaseAbstractModal
                    isOpen={isAsk}
                    children={
                      <div className="ask_modal">
                        <div className="header_section">문의하기</div>
                        <div className="content_section">
                          <div className="text-line flex-center-start">
                            <p className="label-value">신청번호 :</p>
                            <p className="label-value">{contract.contractApplyNumber}</p>
                          </div>
                          <div className="text-line flex-center-start">
                            <p className="label-value">상품명 :</p>
                            <p className="label-value">{contract.spaceProductName}</p>
                          </div>
                          <div className="text-line flex-center-start">
                            <p className="label-value">공용공간 :</p>
                            <p className="label-value">{primaryQuotationName?.facility?.facilityName}</p>
                          </div>
                        </div>

                        <div className="phone_section">
                          <a href={"tel:" + phoneNumber}>{phoneNumber}</a>
                        </div>
                        <div className="footer">
                          {isMobile}
                          <button type="button" className="complete-btn base-btn small-size minmax100" onClick={() => setIsAsk(false)}>
                            확인
                          </button>
                        </div>
                      </div>
                    }
                  />
                )}
              </div>
            </>
          )}
        </section>
      ) : (
        <section className="base-section-layout top-divider">{/* <ServiceTypeGuide /> */}</section>
      )}
    </>
  );
};
export default ServiceTypePaymentInfo;
