import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  deleteReservationsAsync,
  deleteVisitorsAsync,
  getReservationListAsync,
  getVisitorListAsync,
  patchMemberContractManageDeleteAsync,
} from "src/api/contract/contract-api";
import { MemberContractManageModel } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { ConfirmModal } from "src/components";
import { convertKoreanPhoneNumber, YmdFormat } from "src/utils/common-util";

type Props = {
  contractManage: MemberContractManageModel;
  fetchContract: (contractId: string) => void;
  setSearchText: (searchText: string) => void;
};

type Modal = {
  isOpen: boolean;
  message?: string;
};

const UserItem = ({ contractManage, fetchContract, setSearchText }: Props) => {
  // path variable 계약 id
  const { contractId } = useParams();
  const [isDelete, setIsDelete] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDeleteReservations, setIsDeleteReservations] = useState(false);
  const [isDeleteInvitations, setIsDeleteInvitations] = useState(false);
  const [isDeleteReservations2, setIsDeleteReservations2] = useState(false);
  const [isDeleteInvitations2, setIsDeleteInvitations2] = useState(false);
  const [remainingSharedSpaceReservation, setRemainingSharedSpaceReservation] = useState<Number>(0);
  const [remainingVisitorInvitations, setRemainingVisitorInvitations] = useState<Number>(0);
  const [remainingSharedSpaceReservationList, setRemainingSharedSpaceReservationList] = useState<any>();
  const [remainingVisitorInvitationsList, setRemainingVisitorInvitationsList] = useState<any>();
  console.log("contractManage", contractManage);
  const pv: any = {
    contractApplyNumber: contractManage.contractApplyNumber!,
    visitStatus: "VISIT_USE",
    searchStartTime: moment().format(YmdFormat.WITH_TIME_ZONE),
    searchEndTime: "9999-12-31T23:59:59.999+09:00",
  };
  const pr: any = {
    contractId: contractId,
    statusCode: "ACCEPTED,ACKNOWLEDGED,INCOMPLETE",
    organizerMemberNo: "",
    greaterThanEqualsEnd: moment().format(YmdFormat.WITH_TIME_ZONE),
  };
  const [paramsVisitor, setParamsVisitor] = useState<any>(pv);
  const [paramsReservation, setParamsReservation] = useState<any>(pr);
  //잔여 방문자 초대 조회 API
  const { executeAsync: getVisitorList } = useApiOperation(getVisitorListAsync, { noHandleError: true });
  //잔여 공용공간 조회 API
  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync, { noHandleError: true });
  //잔여 방문자 초대 삭제 API
  const { executeAsync: delVisiterByInviter } = useApiOperation(deleteVisitorsAsync);
  //잔여 공용공간 삭제 API
  const { executeAsync: delReservation } = useApiOperation(deleteReservationsAsync);

  // 이용자 삭제 api
  const { executeAsync: patchMemberContractManageDelete } = useApiOperation(patchMemberContractManageDeleteAsync);

  // 확인 버튼만 있는 알림 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 확인 취소 버튼 있는 확인 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  //회원의 공용공간 예약 목록 조회
  const getMemberReservationList = useCallback(
    async (params: any) => {
      params.contractId = contractId;
      params.organizerMemberNo = contractManage.memberNo;
      const response: any = await getReservationList(params);

      setRemainingSharedSpaceReservation(response.data.data.content.length);
      setRemainingSharedSpaceReservationList(response.data.data.content);
    },
    [getReservationList, contractId, contractManage],
  );

  //회원이 초대한 방문자의 목록을 조회
  const getMembersInvitationVisitors = useCallback(
    async (params: any) => {
      if (!params.contractId) {
        params.contractId = contractId;
      }
      if (!params.inviteeMemberNo) {
        params.inviteeMemberNo = contractManage.memberNo;
      }
      const response: any = await getVisitorList(params);
      console.log("called!!", response);
      if (response.data.data.contractVisitor) {
        setRemainingVisitorInvitationsList(response.data.data.contractVisitor);
        setRemainingVisitorInvitations(response.data.data.contractVisitor.length || 0);
      }
    },
    [getVisitorList, contractId, contractManage],
  );

  // 이용자 삭제
  const deleteContractManage = useCallback(async () => {
    try {
      if (!contractId) throw Error("contractId is not found");

      const { data } = await patchMemberContractManageDelete({
        contractManageId: Number(contractManage.contractManageId),
        contractMemberId: Number(contractManage.contractMemberId),
        isDeleted: true,
      });

      if (data?.data?.contractMemberId) {
        // 검색어 초기화
        setSearchText("");
        setAlertModal({ isOpen: true, message: "삭제되었습니다." });
      }
    } catch (error: any) {
      console.error("deleteContractManage error", error);

      let errorMessage = "삭제를 실패했습니다.";
      if (error?.response?.data?.meta?.errorCode === "eCT607") {
        // 계약자 삭제
        errorMessage = "계약자는 삭제가 불가능합니다.";
      }

      setAlertModal({ isOpen: true, message: errorMessage });
    }
  }, [patchMemberContractManageDelete, contractId, contractManage]);

  // 확인 모달 확인 버튼 클릭
  const clickModalConfirm = useCallback(() => {
    setConfirmModal({ isOpen: false });

    deleteContractManage();
  }, [deleteContractManage]);

  // 알림 모달 확인 버튼 클릭
  const clickAlertModalConfirm = useCallback(async () => {
    if (!contractId) throw Error("contractId is not found");

    await fetchContract(contractId);
    setAlertModal({ isOpen: false });
  }, [contractId]);

  const delDisable = useMemo(() => {
    return Number(remainingSharedSpaceReservation) !== 0 || Number(remainingVisitorInvitations) !== 0;
  }, [remainingSharedSpaceReservation, remainingVisitorInvitations]);

  //잔여 공용공간 삭제 함수
  const deleteReservation = useCallback(
    async (contractManage: any) => {
      //promise.allSettled 사용하여 삭제 시 쪼개서 순회하기
      let chunk: any = [];
      for (let i = 0; i < remainingSharedSpaceReservationList.length; i += 20) {
        const innerList: any = remainingSharedSpaceReservationList.slice(i, i + 20);
        let innerArr: any = [];

        for (let j = 0; j < innerList.length; j++) {
          // TODO 삭제 API 태울 것
          innerArr.push(delReservation({ contractId: String(contractId), reservationId: String(innerList[j].id) }));
        }
        chunk.push(innerArr);
      }

      const result: any = await Promise.allSettled(chunk);
      // console.log("result", result);
      let resultCheck = false;
      let retryChunk: any = [];
      result.forEach((res: any, index: number) => {
        if (res.status !== "fulfilled") {
          res.value.forEach((innerData: any) => {
            if (innerData.status !== "fulfilled") {
              retryChunk.push(delReservation({ contractId: String(contractId), reservationId: String(innerData.data.data.reservation.id) }));
            }
          }, []);
        }
      }, []);

      //실패한 건들을 한번 더 삭제를 시도
      if (retryChunk.length > 0) {
        const retryResult: any = await Promise.allSettled(retryChunk);
        retryResult.forEach((res: any, index: number) => {
          if (res.status !== "fulfilled") {
            resultCheck = true;
          }
        }, []);
      }

      // 최종적으로 성공이면
      if (resultCheck === false) {
        await getMemberReservationList(paramsReservation);

        setIsDeleteReservations2(true);
      } else {
        //최종적으로 실패시에는? 관리자에게 문의하세요?
      }
    },
    [remainingSharedSpaceReservationList, delReservation, contractId, getMemberReservationList, paramsReservation],
  );

  //잔여 방문자 초대 삭제 함수
  const deleteVisitors = useCallback(
    async (contractManage: any) => {
      //promise.allSettled 사용하여 삭제 시 쪼개서 순회하기
      let chunk: any = [];

      for (let i = 0; i < remainingVisitorInvitationsList.length; i++) {
        chunk.push(remainingVisitorInvitationsList[i].contractVisitorId);
      }
      // console.log("contractManage", contractManage);
      const rtnData: any = await delVisiterByInviter({ inviteeMemberNo: contractManage.memberNo!, contractVisitorIds: chunk });
      if (rtnData.status > 199 && rtnData.status < 399) {
        await getMembersInvitationVisitors(paramsVisitor);
        setIsDeleteInvitations2(true);
      }
    },
    [delVisiterByInviter, getMembersInvitationVisitors, paramsVisitor, remainingVisitorInvitationsList],
  );

  const deleteReservations = useCallback(
    async (user: any) => {
      setIsDeleteReservations(false);
      await deleteReservation(user);
    },
    [deleteReservation],
  );
  const deleteInvitations = useCallback(
    async (user: any) => {
      // setRemainingVisitorInvitations(0);
      setIsDeleteInvitations(false);
      await deleteVisitors(user);
    },
    [deleteVisitors],
  );

  return (
    <div className="contents-card">
      <div className="contents-card__main-area">
        <div className="left-section">
          <p>{convertKoreanPhoneNumber(contractManage.inviteMobileNumber)}</p>
          <p>{contractManage.email || (contractManage.memberStatus === "MEMBER_INVITE" ? "초대완료 (미가입)" : "미가입 (만료)")}</p>
        </div>
        <div className="right-section">
          <div>
            <p>{`${moment(contractManage.inviteDate).format("YYYY-MM-DD H:mm")}`}</p>
          </div>
          {contractManage.memberStatus !== "MEMBER_DELETE" && contractManage.memberStatus !== "MEMBER_CLOSE" && (
            <div>
              {contractManage.isAdmin || contractManage.isContractor ? (
                <div className="base-chip">
                  <span>마스터</span>
                </div>
              ) : (
                <div>
                  {contractManage.memberStatus === "MEMBER_EXPIRE" && (
                    <button
                      type="button"
                      data-invite-mobile-number={contractManage.inviteMobileNumber}
                      data-contract-member-id={contractManage.contractMemberId}
                      className="only-text-btn pr10 btn-reinvite"
                      style={{ borderRight: "1px solid #999999" }}
                    >
                      재초대
                    </button>
                  )}
                  <button
                    type="button"
                    className="only-text-btn pl10 btn-delete"
                    onClick={async () => {
                      setConfirmModal({ isOpen: true });

                      if (paramsReservation.contractId) {
                        setParamsReservation({ ...paramsReservation, contractId: contractId });
                        await getMemberReservationList({ ...paramsReservation, contractId: contractId });
                      }
                      if (contractManage.contractManageId) {
                        setParamsVisitor({
                          ...paramsVisitor,
                          contractId: contractId,
                          contractManageId: contractManage.contractManageId,
                        });
                        await getMembersInvitationVisitors({
                          ...paramsVisitor,
                          contractId: contractId,
                          contractManageId: contractManage.contractManageId,
                        });
                      }
                      // 3. 0.5초안에 호출된 가장 마지막 api만 서버로 호출합니다.
                    }}
                  >
                    삭제
                  </button>
                  <ConfirmModal
                    className="minmax350 px20"
                    isOpen={confirmModal.isOpen}
                    btnLeftTitle={"취소"}
                    btnRightTitle={"삭제"}
                    btnRightDisable={delDisable}
                    onClose={() => setConfirmModal({ isOpen: false })}
                    onClick={() => clickModalConfirm()}
                    children={
                      <div>
                        <p className="font18 font-weight-600">이용자를 삭제하시겠습니까?</p>
                        <div>
                          <p className="font15 font-weight-400 mt20">
                            잔여 공용공간 예약 및 방문자 초대가 없는 경우
                            <br /> 이용자를 삭제할 수 있습니다.
                          </p>
                        </div>

                        <div className="flex-center-between minmax310 mt20" style={{ backgroundColor: "#F2F4F6", height: 52, padding: 20 }}>
                          <div>잔여 공용공간 예약 :{remainingSharedSpaceReservation + "건"}</div>
                          <button
                            className={`only-text-btn pl10 btn-delete size-30 ${
                              Number(remainingSharedSpaceReservation) === 0 ? "text-primary5" : ""
                            }`}
                            disabled={Number(remainingSharedSpaceReservation) === 0}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsDeleteReservations(true);
                            }}
                          >
                            삭제
                          </button>
                        </div>

                        <div className="flex-center-between minmax310 mt10" style={{ backgroundColor: "#F2F4F6", height: 52, padding: 20 }}>
                          <div>잔여 방문자 초대 :{remainingVisitorInvitations + "건"}</div>
                          <button
                            className={`only-text-btn pl10 btn-delete size-30 ${Number(remainingVisitorInvitations) === 0 ? "text-primary5" : ""}`}
                            disabled={Number(remainingVisitorInvitations) === 0}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsDeleteInvitations(true);
                            }}
                          >
                            삭제
                          </button>
                        </div>
                        <p className="font15 font-weight-400 mt20">삭제 후 즉시 해당 이용자는</p>
                        <p className="font15 font-weight-400 mt5">더 이상 공간에 출입이 불가합니다.</p>
                      </div>
                    }
                  />

                  {/* 공용공간 삭제 */}
                  <ConfirmModal
                    isOpen={isDeleteReservations}
                    btnLeftTitle={"취소"}
                    btnRightTitle={"삭제"}
                    onClose={() => setIsDeleteReservations(false)}
                    onClick={() => deleteReservations(contractManage)}
                  >
                    <div>
                      <p className="font18 font-weight-600">해당 이용자의 잔여 공용공간 예약을 모두 삭제하시겠습니까?</p>
                    </div>
                  </ConfirmModal>

                  {/* 방문자 초대 삭제 */}
                  <ConfirmModal
                    isOpen={isDeleteInvitations}
                    btnLeftTitle={"취소"}
                    btnRightTitle={"삭제"}
                    onClose={() => setIsDeleteInvitations(false)}
                    onClick={() => deleteInvitations(contractManage)}
                  >
                    <div>
                      <p className="font18 font-weight-600">해당 이용자의 잔여 방문자 초대를 모두 삭제하시겠습니까?</p>
                    </div>
                  </ConfirmModal>
                  <ConfirmModal isOpen={isDeleteReservations2} btnRightTitle={"확인"} onClick={() => setIsDeleteReservations2(false)}>
                    <div>
                      <p className="font18 font-weight-600">해당 이용자의 잔여 공용공간 예약이 모두 삭제되었습니다.</p>
                    </div>
                  </ConfirmModal>

                  {/* 방문자 초대 삭제 */}
                  <ConfirmModal isOpen={isDeleteInvitations2} btnRightTitle={"삭제"} onClick={() => setIsDeleteInvitations2(false)}>
                    <div>
                      <p className="font18 font-weight-600">해당 이용자의 잔여 방문자 초대가 모두 삭제되었습니다.</p>
                    </div>
                  </ConfirmModal>
                  <ConfirmModal isOpen={alertModal.isOpen} btnRightTitle={"확인"} onClick={() => clickAlertModalConfirm()}>
                    <div>
                      <p className="font18 font-weight-600">{alertModal?.message}</p>
                    </div>
                  </ConfirmModal>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {(contractManage.memberStatus === "MEMBER_CLOSE" || contractManage.memberStatus === "MEMBER_DELETE") && (
        <div className="contents-card__info-area">
          <p>
            관리자에 의해 권한이 일시 정지되었습니다.
            <br />
            자세한 사항은 CS 센터로 문의 바랍니다.
          </p>
          <button type="button" className="icon-right-btn">
            문의하기
          </button>
        </div>
      )}
    </div>
  );
};
export default UserItem;
