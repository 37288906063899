import { useEffect, useRef } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { tooltipContainerState, TooltipContainer } from "../../recoil/tooltip/atom";
import { useAnonymousPathPatterns } from "src/pages/hooks/anonymous-path-patterns";
import { useMemberInfoState } from "src/recoil/member/hook";
import { scrollState } from "src/recoil/scroll/atom";
import RoutesList from "src/routes/RoutesList";
import ErrorModal from "../ErrorModal";
import RefreshTokenErrorModal from "../RefreshTokenErrorModal";
import ToastList from "../ToastList";
import BreadCrumbs from "./BreadCrumbs";
import FooterPC from "./FooterPC";
import HeaderPC from "./HeaderPC";
import ScrollToTop from "./ScrollToTop";
import taapspace_logo from "src/assets/images/logo_taapspace.svg";
import { Link } from "react-router-dom";

/**
 * App > Container > Layout
 */
function Layout() {
  const scrollTopRef = useRef<any>(null);
  const scrollRef = useRef<any>(null);
  const baseLayoutContentsRef = useRef<HTMLDivElement>(null);
  const setTooltipContainer = useSetRecoilState<TooltipContainer>(tooltipContainerState);
  const { fetchMemberMe } = useMemberInfoState();
  const [scroll, setScroll] = useRecoilState(scrollState);
  // 무인증 페이지 여부
  const { matchAnonymous } = useAnonymousPathPatterns();

  useEffect(() => {
    // css property 사용하여 ios 모바일에서 스크롤 생기는 문제 해결
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
    if (window.ReactNativeWebView) {
      document.body.classList.add("open-taap");
    }
  });

  useEffect(() => {
    if (baseLayoutContentsRef.current) {
      setTooltipContainer({ element: baseLayoutContentsRef.current });
    }
  }, [setTooltipContainer]);

  useEffect(() => {
    // 로그인한 사용자 정보 조회
    // if (!matchAnonymous) {
    fetchMemberMe();
    // }
  }, [matchAnonymous, fetchMemberMe]);

  // useEffect(() => {
  //   // 스크롤위치 저장
  //   scrollRef.current.addEventListener("scroll", () => {
  //     setScroll(scrollRef.current.scrollTop);
  //   });
  //   return () => {
  //     scrollRef.current.removeEventListener("scroll", () => {
  //       setScroll(scrollRef.current.scrollTop);
  //     });
  //   };
  // }, []);

  return (
    <>
      <ScrollToTop containerRef={scrollTopRef} />
      <div className="base-layout-wrap">
        <section className="pc-left">
          <div className="pc-left__layout">
            <div className="pc-left__layout-top"></div>
            <div className="pc-left__layout-center">
              <img width={200} src={taapspace_logo} alt="logo" />
              <p>공간이 필요한 모든 순간, TaapSPACE로 쉽고 간편하게!</p>
              <h2>TaapSPACE에서 다양한 공간을 만나보세요</h2>
            </div>
            <div className="pc-left__layout-bottom">
              <div className="description">
                <div className="app-icon"></div>
                <div>
                  <h3>출입, 예약 등 공간 이용에 편리함을 드려요.</h3>
                  <p>앱스토어, 구글플레이에서 다운로드 가능합니다.</p>
                </div>
              </div>
              <div className="store-btn-wrap">
                <a href="https://apps.apple.com/us/app/taap/id1619932099" target="_blank" rel="noreferrer">
                  <button className="download-appstore"></button>
                </a>
                <a href="https://play.google.com/store/apps/details?id=space.pnpt.fez.taap&pli=1" target="_blank" rel="noreferrer">
                  <button className="download-googleplay"></button>
                </a>
              </div>
            </div>
          </div>
        </section>
        <main className={`base-layout-container`} ref={scrollRef}>
          <div ref={scrollTopRef}></div>
          <BreadCrumbs />
          <div className="base-layout">
            <div className="base-layout-contents" ref={baseLayoutContentsRef}>
              <RoutesList />
            </div>
          </div>
        </main>
      </div>
      <ToastList />
      <RefreshTokenErrorModal />
      <ErrorModal />
    </>
  );
}

export default Layout;
