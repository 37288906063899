import axios from "axios";
import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";

import "react-pdf/dist/Page/TextLayer.css";
import { uploadFilesAsync } from "src/api/file/file-api";
import { MediaFile } from "src/api/file/file-types";
import useApiOperation from "src/api/hooks/api-operation";

import onErrorImage from "src/assets/images/onerror_preview.svg";
import BaseAbstractModal from "./BaseAbstractModal";
import BaseModal from "./BaseModal";
import { Page, PasswordResponses, pdfjs, Document } from "react-pdf";

import useCourtAuth from "./court-auth/hooks/court-auth";

// import workerSrc from "../../../build/static/media/pdf.worker.min.7149cfc1274c38029805.mjs";

// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface Prop {
  fileList: any;
  isOnlyView?: boolean;
  setFiles?: any;
  errorText?: string;
  limit?: number;
  maxSize?: number;
  disabled?: boolean;
  serviceTag?: string;
  type?: "PROOF" | "LOGO";
  maxWidth?: number;

  setRef?: (ref: React.RefObject<HTMLInputElement>) => void; // 관리자 메모필수
  mode?: "memo"; // 관리자 메모필수
  containerClass?: string;
}
const s3Url = process.env.REACT_APP_S3_BASEURL;
interface Message {
  title: string;
  message: string;
}

// Taap 에서 제공하는 Asset 정보
type DocumentPickerAsset = {
  /**
   * Document original name.
   */
  name: string;
  /**
   * Document size in bytes.
   */
  size?: number;
  /**
   * An URI to the local document file.
   */
  uri: string;
  /**
   * Document MIME type.
   */
  mimeType?: string;
  /**
   * Timestamp of last document modification.
   */
  lastModified?: number;
  /**
   * `File` object for the parity with web File API.
   * @platform web
   */
  file?: File;
};

// Taap 에서 전달한 파일 정보
type DeviceFile = {
  base64Text: string;
  asset: DocumentPickerAsset;
};

const BaseFileUpload = ({
  setFiles,
  fileList,
  errorText,
  isOnlyView,
  limit = 10,
  maxSize = 10,
  disabled,
  serviceTag,
  type = "PROOF",
  maxWidth = 1070,
  setRef,
  mode,
  containerClass,
}: Prop) => {
  /**
   * useRef
   */
  // React Native WebView 체크
  const isReactNativeWebView = useRef(!!window.ReactNativeWebView).current;

  const sizeNlimitText = `한 파일당 업로드 용량은 ${maxSize}MB, 최대 ${limit}개까지만 가능합니다.`;
  const sizeText = `파일은 최대 ${limit}개까지만 업로드 가능합니다.`;

  const [isOpen, setIsOpen] = useState(false);
  const mb = 1024 * 1024;
  const fileRef = useRef<HTMLInputElement>(null);
  const [token, setToken] = useState("");
  const [isFullScreenImageOpen, setIsFullScreenImageOpen] = useState(false);
  const [focusFullScreenImageIndex, setFocusFullScreenImageIndex] = useState(0);
  const [previewImage, setPreviewImage] = useState(fileList);
  const [numPages, setNumPages] = useState<number>(0);
  const [message, setMessage] = useState<Message>({ title: "", message: "" });
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { getIdTokenAsync } = useCourtAuth();
  // const { executeAsync: postImageFiles } = useApiOperation(uploadOrgPrivateFilesAsync);

  const { executeAsync: postImageFile } = useApiOperation(uploadFilesAsync);

  /**
   * useCallback
   */
  // 파일 선택하기 (WebView 전용)
  const onClickSelectFile = useCallback(() => {
    if (window.ReactNativeWebView) {
      const type = "file";
      const data = {
        event: "select",
        multiple: false, // 여러개 파일 선택 가능하게 할지 여부
        mimeTypes: ["image/*", "application/pdf"], // 선택 허용할 mimeType
        isConvertToJpg: true, // jpg, jpeg, png 파일이 아닌경우 jpg 로 포맷을 변환할지 여부
      };
      window.ReactNativeWebView.postMessage(JSON.stringify({ type, data }));
    }
  }, []);

  // base64 -> file
  const convertBase64ToFile = useCallback((base64Text: string, asset: DocumentPickerAsset) => {
    const base64Split = base64Text.split(",");
    const bText = window.atob(base64Split[base64Split.length - 1]);
    let bTextLength = bText.length;
    const uint8Array = new Uint8Array(bTextLength);

    while (bTextLength--) {
      uint8Array[bTextLength] = bText.charCodeAt(bTextLength);
    }

    const { name, mimeType } = asset || {};
    const file = new File([uint8Array], name, { type: mimeType });

    return file;
  }, []);

  // file -> base64
  const fileToBase64 = useCallback(async (file: File) => {
    const base64Text = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
    });
    return base64Text;
  }, []);

  // 전달받은 파일 처리
  const onDeviceFile = useCallback(
    async (fileInfoText?: string) => {
      const deviceFiles: Array<DeviceFile> = JSON.parse(fileInfoText || "{}");

      if (!deviceFiles?.[0]) {
        return;
      }

      const deviceFile = deviceFiles[0];

      const { asset, base64Text } = deviceFile;

      // 파일 용량 체크
      const sizeLimit = maxSize * mb;
      if (asset.size && asset.size > sizeLimit) {
        errorPopup("첨부파일 업로드가 실패했습니다.", sizeNlimitText);
        return;
      }

      const file = convertBase64ToFile(base64Text, asset);

      const formData = new FormData();
      formData.append("file", file);
      const res = await postImageFile({ file: formData, type: type === "PROOF" ? "private" : "public" });

      if (res && res.status >= 200 && res.status <= 299) {
        const uploaded: any = [];
        const mediaFile = {
          ...res.data.data.media,
          ...{ type, serviceTag, isDeleted: false, isRemoved: false },
        };

        try {
          const convertedBase64Text = await fileToBase64(file);
          uploaded.push({ ...mediaFile, blobUrl: convertedBase64Text });

          setFiles([...fileList, ...uploaded]);
        } catch (error) {
          console.error("Error reading file:", error);
        }
      }
    },
    [convertBase64ToFile, fileList, fileToBase64, maxSize, mb, postImageFile, serviceTag, setFiles, sizeNlimitText, type],
  );

  // Taap -> TaapSpace 파일 전달 처리
  useEffect(() => {
    window.court.webViewFunction.onDeviceFile = onDeviceFile;

    return () => {
      window.court.webViewFunction.onDeviceFile = () => {};
    };
  }, []);

  useEffect(() => {
    if (setRef) {
      setRef(fileRef);
    }
  }, [fileRef, setRef]);

  useEffect(() => {
    const escapeKeyHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.preventDefault();
        setIsFullScreenImageOpen(false);
      }
    };

    window.addEventListener("keyup", escapeKeyHandler);

    return () => {
      window.removeEventListener("keyup", escapeKeyHandler);
    };
  }, []);

  // 드래그로 변경될 내용들을 담아줄 객체
  // const [dragAndDrop, setDragAndDrop] = useState<DragDropState>({
  //   draggedFrom: null, //드래그가 시작하는 index
  //   draggedTo: null, // 변경될 드래그 index
  //   isDragging: false,
  //   originalOrder: [],
  //   updatedOrder: [],
  // });

  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  // pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();

  // pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.7149cfc1274c38029805.mjs";

  const errorPopup = (title: string, message: string) => {
    setMessage({ title, message });
    setIsOpen(true);
  };

  const sizeCheck = (files: FileList, sizeLimit: number) => {
    let checker = true;
    for (let i = 0; i < files.length; i++) {
      // console.log(e.target.files[i].size, sizeLimit);
      if (files[i].size > sizeLimit) {
        checker = false;
        break;
      }
    }
    return checker;
  };

  const limitCheck = (files: FileList, fileList: any, limit: number) => {
    let checker = true;
    const isDeletedTrue = fileList.some((file: any) => file.isDeleted === true);
    const nd: any = fileList.filter((obj: any) => obj.isDeleted === false);
    let totalFiles;

    if (isDeletedTrue) {
      totalFiles = files.length + nd.length;
    } else {
      totalFiles = files.length + fileList.length;
    }

    if (limit < totalFiles) {
      checker = false;
    }

    return checker;
  };

  const isLimiedFileValidatiaon = (files: FileList, fileList: any, maxSize?: number, limit?: number) => {
    // console.log("maxSize, limit", maxSize, limit);
    let checker = true;
    let sizeLimit = mb;
    console.log("사이즈", maxSize, limit);
    //파일의 사이즈 체크
    if (maxSize !== undefined && limit === undefined) {
      sizeLimit = maxSize * mb;
      if (sizeCheck(files, sizeLimit)) {
        errorPopup("첨부파일 업로드가 실패했습니다.", sizeText);
        checker = false;
        return;
      }
    }
    if (limit !== undefined && maxSize === undefined) {
      if (!limitCheck(files, fileList, limit)) {
        errorPopup("첨부파일 업로드가 실패했습니다.", sizeText);
        checker = false;
        return;
      }
    }

    if (limit !== undefined && maxSize !== undefined) {
      // console.log("둘다 있다.");
      sizeLimit = maxSize * mb;
      if (sizeCheck(files, sizeLimit) === false || limitCheck(files, fileList, limit) === false) {
        errorPopup("첨부파일 업로드가 실패했습니다.", sizeNlimitText);
        checker = false;
        return;
      }
    }
    return checker;
  };

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>, fileList: any, maxSize?: number, limit?: number) => {
    const uploaded: any = [];
    console.log("e.target", e.target.files);
    if (e.target.files && isLimiedFileValidatiaon(e.target.files, fileList, maxSize, limit) && e.target.files && e.target.files.length) {
      const arrFiles = Array.from(e.target.files);

      const files = arrFiles.map((file, index) => {
        const src = window.URL.createObjectURL(file);
        return { file, id: index, src };
      });

      for (const i in files) {
        const formData = new FormData();
        formData.append("file", files[i].file);
        const res = await postImageFile({ file: formData, type: type === "PROOF" ? "private" : "public" });

        if (res && res.status >= 200 && res.status <= 299) {
          const mediaFile = JSON.parse(JSON.stringify({ ...res.data.data.media, ...{ type, serviceTag, isDeleted: false, isRemoved: false } }));
          const file = e.target.files[i];

          try {
            const blobUrl = await fileToBase64(file);
            uploaded.push({ ...mediaFile, blobUrl });
          } catch (error) {
            console.error("Error reading file:", error);
          }
        }
      }
    }
    e.target.value = "";
    setFiles([...fileList, ...uploaded]);
  };

  const getToken = useCallback(async () => {
    const idToken = await getIdTokenAsync();
    setToken(idToken!);
  }, [getIdTokenAsync]);

  const handleOpenFullImage = (idx: number) => {
    setFocusFullScreenImageIndex(idx);
    setIsFullScreenImageOpen((prev) => !prev);
  };
  // 풀스크린 좌우 버튼 핸들러
  const handleFullScreenImage = (position: "left" | "right") => {
    if (position === "left") {
      setFocusFullScreenImageIndex((prev) => (prev > 0 ? prev - 1 : prev));
      setPageNumber(1);
    }
    if (position === "right") {
      setFocusFullScreenImageIndex((prev) => (prev < previewImage.length - 1 ? prev + 1 : prev));
      setPageNumber(1);
    }
  };

  const handleDeleteFile = (e: any, passFile: MediaFile) => {
    e.preventDefault();

    let newArr = [];

    if (!passFile.id) {
      newArr = fileList.filter((item: MediaFile) => item.key !== passFile.key);
    } else {
      newArr = fileList.map((file: any) => {
        if (file.id === passFile.id) {
          file = { ...file, isRemoved: true, isDeleted: true, cmdType: "D" };
        }
        return file;
      });
    }
    setFiles(newArr);
  };

  const findImageURL = (file: any) => {
    if (file.blobUrl) {
      return file.blobUrl;
    } else if (file.type === "LOGO") {
      return file.url;
    } else if (file.type === "PROOF") {
      return file.url + "?access_token=" + token;
    } else if (!file.type && file.url) {
      return file.url + "?access_token=" + token;
    } else if (file.key?.includes("http")) {
      return file.key + "?access_token=" + token;
    } else if (!file.key?.includes("http") && file.key) {
      return s3Url + file?.key! + "?access_token=" + token;
    } else return "";
  };

  useEffect(() => {
    if (token === "") {
      getToken();
    }
  }, [getToken, fileList, setFiles, token]);

  useEffect(() => {
    setPreviewImage(fileList);
  }, [fileList, previewImage]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handlePdfLeft = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  const handlePdfRight = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handleImageDownload = async (filename: string, url: string) => {
    try {
      const response = await axios.get(url + "?access_token=" + token, { responseType: "blob" });
      const blob = new Blob([response.data]);
      const blobURL = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = filename;
      link.click();
    } catch (error) {
      console.log("다운로드실패", error);
    }
  };

  function onPassword(callback: (arg0: any) => void, reason: any) {
    function callbackProxy(password: string | null) {
      // Cancel button handler
      if (password === null) {
        // Reset your `document` in `state`, un-mount your `<Document />`, show custom message, whatever
        return setIsFullScreenImageOpen(false);
      }

      callback(password);
    }
    console.log("reason :>> ", reason);

    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        const password = prompt("비밀번호를 입력해주세요.");
        callbackProxy(password);
        break;
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        const password = prompt("비밀번호가 맞지 않습니다 다시 입력해주세요.");
        callbackProxy(password);
        break;
      }
      default:
        callbackProxy(null);
    }
  }

  const renderFileView = (file: any) => {
    const fileUrlWithToken = file.blobUrl
      ? file.blobUrl
      : file.url
      ? file.url + "?access_token=" + token
      : s3Url + file.key + "?access_token=" + token;

    const fileName = file.filename;
    const fileExtension = file.filename.slice(file.filename.lastIndexOf(".") + 1);
    const availableFileExtensionArray = ["jpg", "jpeg", "gif", "png", "svg", "bmp"];

    // 이미지
    if (availableFileExtensionArray?.includes(fileExtension.toLowerCase())) {
      return (
        <>
          <img
            src={findImageURL(file)}
            alt={fileName || "이미지를 불러오는데 실패했습니다."}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = onErrorImage;
            }}
          />
          {/* <p className="file-name">{fileName}</p> */}
        </>
      );
    }

    // 이미지가 아닐경우
    switch (fileExtension) {
      case "pdf":
        return (
          <div className="file-viewer-pdf">
            <Document
              className="file-viewer-pdf__document"
              file={fileUrlWithToken ? fileUrlWithToken : ""}
              onLoadSuccess={onDocumentLoadSuccess}
              onPassword={onPassword}
            >
              <Page pageNumber={pageNumber} renderAnnotationLayer={false} loading={"loading..."} onLoadError={console.error} />
            </Document>
            <div className="button-wrap">
              <button className="left" disabled={pageNumber === 1} onClick={handlePdfLeft}></button>
              <button className="right" disabled={pageNumber === numPages} onClick={handlePdfRight}></button>
            </div>
          </div>
        );
      default:
        return (
          <div className="no-preview-available">
            <p>미리보기를 제공하지 않는 파일 형식입니다.</p>
            <p>다운로드 후 확인해 주세요.</p>
            <p className="file-name">{fileName}</p>
          </div>
        );
    }
  };

  const sliceTitle = (fileName: string) => {
    const dotIndex = fileName.lastIndexOf(".");
    const title = fileName.slice(0, dotIndex);
    const extension = fileName.slice(dotIndex + 1);
    return { title, extension };
  };

  // TODO: 드래그로 파일 순서 변경은 추후 적용 - Drag&Drop 코드 삭제하지 말것
  // ============================= 삭제 NO ===========================
  // 드래그하려고 시작할 때
  // const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.currentTarget.style.opacity = "0.4";
  //   const initialPosition = Number(event.currentTarget.dataset.position);

  //   setDragAndDrop({
  //     ...dragAndDrop,
  //     draggedFrom: initialPosition,
  //     originalOrder: previewImage,
  //   });
  // };

  // const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.preventDefault();

  //   let newList = dragAndDrop.originalOrder;
  //   const draggedFrom = dragAndDrop.draggedFrom; // 드래그 되는 항목의 인덱스(시작)
  //   const draggedTo = Number(event.currentTarget.dataset.position); // 놓을 수 있는 영역의 인덱스(끝)
  //   const itemDragged = newList[draggedFrom || 0]; //

  //   const remainingItems = newList.filter((item, idx) => idx !== draggedFrom); //draggedFrom(시작) 항목 제외한 배열 목록

  //   newList = [...remainingItems.slice(0, draggedTo), itemDragged, ...remainingItems.slice(draggedTo)];
  //   // 놓을 수 있는 영역이 변경 되면 객체를 변경해줌
  //   if (draggedTo !== dragAndDrop.draggedTo) {
  //     setDragAndDrop({
  //       ...dragAndDrop,
  //       updatedOrder: newList,
  //       draggedTo: draggedTo,
  //     });
  //   }
  // };

  // // onDrop 잡은 Item을 적절한 곳에 놓았을 때 발생하는 이벤트 (다른 item이랑 겹쳐졌을 때)
  // const onDrop = () => {
  //   setPreviewImage(dragAndDrop.updatedOrder);
  //   setFiles(dragAndDrop.updatedOrder);
  //   setDragAndDrop({
  //     ...dragAndDrop,
  //     draggedFrom: null,
  //     draggedTo: null,
  //   });
  // };

  // // onDragLeave 범위를 벗어나면 발생하는 이벤트
  // const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.currentTarget.classList.remove("over");
  //   setDragAndDrop({
  //     ...dragAndDrop,
  //     draggedTo: null,
  //   });
  // };

  // // 다른 item이랑 겹쳐졌을 때 발생하는 이벤트 (1번만)
  // const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.currentTarget.classList.add("over");
  // };

  // // 잡은 Item을 놓았을 때 발생하는 이벤트 (다른 item이랑 겹치지 않아도 발생함)
  // const onDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.currentTarget.style.opacity = "1";

  //   const files = [...previewImage];

  //   const listItems = document.querySelectorAll(".draggable");
  //   listItems.forEach((item) => {
  //     item.classList.remove("over");
  //   });

  //   const newFiles = files.map((file, idx) => {
  //     return { ...file, orderNums: String(idx), cmdType: file.id ? "U" : "C" };
  //   });
  //   setFiles(newFiles);
  //   setPreviewImage(files);
  // };
  // ============================= 삭제 NO ===========================
  return (
    <div
      // className="base-image-wrap"
      className={`contents-container__grid-contents ${containerClass}`}
    >
      <div className={`base-file`} style={{ width: maxWidth }}>
        <div className="base-file__list">
          <input
            type="file"
            name="img-loader-input"
            ref={fileRef}
            onChange={(e: any) => onChange(e, fileList, maxSize, limit)}
            style={{ display: "none" }}
            accept=".png, .jpg, .jpeg, .pdf"
          />

          <>
            {/* <div className={`${Array.isArray(fileList) && fileList.length > 1 ? "fit-start-line" : ""}`}> */}
            <div className="flex-center" style={{ overflowX: "auto" }}>
              {fileList
                ?.filter((file: MediaFile) => !file.isRemoved)
                .sort((a: MediaFile, b: MediaFile) => Number(a.orderNums) - Number(b.orderNums))
                .map(
                  (file: any, index: number) =>
                    file.isDeleted === false && (
                      <div
                        className={`file-upload-wrap ${isOnlyView ? "detail" : "form"}`}
                        key={index}
                        draggable={true}
                        data-position={index}
                        onClick={() => handleOpenFullImage(index)}
                      >
                        <img
                          src={findImageURL(file)}
                          alt="preview-images"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = onErrorImage;
                          }}
                        />
                        {!isOnlyView && (
                          <button
                            type="button"
                            className="file-delete-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteFile(e, file);
                            }}
                          />
                        )}

                        <div className="title-area">
                          <div className="not-hover file-name">
                            <p>{sliceTitle(file.filename).title}</p>
                            <span>.{sliceTitle(file.filename).extension}</span>
                          </div>
                          <div className="active-hover">
                            <p>{sliceTitle(file.filename).title}</p>
                            <span>.{sliceTitle(file.filename).extension}</span>
                            {isOnlyView && (
                              <button
                                type="button"
                                className="detail-download"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleImageDownload(file.filename, file.url ? file.url : s3Url + file.key);
                                }}
                              ></button>
                            )}
                          </div>
                        </div>
                      </div>
                    ),
                )}
            </div>
            {isFullScreenImageOpen && (
              <BaseAbstractModal isNotUseWhiteBoard isOpen={isFullScreenImageOpen} className="base-image" opacity={0.75}>
                <div className="base-image__full-screen">
                  {renderFileView(previewImage[focusFullScreenImageIndex])}
                  <p className="file-name">{previewImage[focusFullScreenImageIndex].fileName}</p>
                  <button className="close-preview-btn" onClick={() => setIsFullScreenImageOpen(false)}></button>
                </div>
              </BaseAbstractModal>
            )}
          </>
          {!isOnlyView && (
            <button
              type="button"
              disabled={disabled}
              className={`file-upload-btn ${clsx({ "d-none": mode === "memo", gray: fileList.length === 1 })}`}
              onClick={() => {
                // Taap Webview 인 경우
                if (isReactNativeWebView) {
                  onClickSelectFile();
                }

                // 기본 이벤트
                else {
                  fileRef.current?.click();
                }
              }}
            >
              <p className="pt40 text-primary3 font13"> {fileList.filter((noDel: any) => !noDel.isDeleted && !noDel.isRemoved)?.length || 0}/1</p>
            </button>
          )}
        </div>
        {isOpen && (
          <BaseModal isOpen={isOpen} onClose={() => setIsOpen(false)} title={message.title}>
            <p>{message.message}</p>
          </BaseModal>
        )}
        {errorText && <p className={clsx("validation-text", { "d-none": mode === "memo" })}>{errorText}</p>}
        {/* {!isOnlyView && (previewImage.length === 0 || previewImage.filter((image: any) => !image.isRemoved).length === 0) && (
          <ul className={clsx("base-list mt10", { "d-none": mode === "memo" })}>
            <li>jpeg, jpg, png, pdf</li>
            <li>업로드 파일 1개,용량은 10MB까지 가능합니다.</li>
          </ul>
        )} */}
      </div>
    </div>
  );
};
export default BaseFileUpload;
