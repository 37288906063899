import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMemberContractsAsync } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { postWithdrawalAsync } from "src/api/member/member-api";
import { BaseButton, ConfirmModal } from "src/components";
import { BaseCheckbox } from "src/components/BaseCheckbox";
import Header from "src/components/layout/Header";
import { Modal } from "src/pages/contract/contract-types";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import { useMemberInfoState } from "src/recoil/member/hook";

// 회원탈퇴 유의사항
const Withdrawal = () => {
  const [agree, setAgree] = useState(false);
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  const { goBack } = useNavigateGoBack();
  const navigate = useNavigate();
  const { logout } = useMemberInfoState();
  const { executeAsync: getContracts } = useApiOperation(getMemberContractsAsync);
  const { executeAsync: postWithdrawal } = useApiOperation(postWithdrawalAsync);
  const { member } = useMemberInfoState();

  const handleWithdrawal = async () => {
    if (member?.memberNo) {
      const { data: contractData, status: contractStatus } = await getContracts({
        memberNo: member?.memberNo,
        contractSteps: "APPLY_CONFIRM,CONTRACT_PLAN,CONTRACT_ACCEPT,USE_APPROVAL,USE_PROGRESS,TERMINATE_RECEIVED",
      });

      if (contractStatus >= 200 && contractStatus < 300 && contractData.data.content) {
        if (contractData.data.content && contractData.data.content.length > 0) {
          const filteredF2Contractor = contractData.data.content?.filter(
            (contract) => contract.isContractor && contract.spaceProductType === "FULL_COURT",
          );
          const filteredS2Contractor = contractData.data.content?.filter(
            (contract) => contract.isContractor && contract.spaceProductType === "TIME_COURT",
          );
          if (filteredF2Contractor.length > 0) {
            setConfirmModal({ isOpen: true, type: "F2_CONTRACT_MANAGER" });
            return;
          }
          if (filteredS2Contractor.length > 0) {
            setConfirmModal({ isOpen: true, type: "S2_CONTRACT_MANAGER" });
            return;
          }
          navigate("/court/mypage/profile/withdrawalReservation");
        } else {
          const { status } = await postWithdrawal();
          if (status >= 200 && status < 300) {
            logout();
            navigate("/court/mypage/profile/withdrawalComplete");
          }
        }
      }
    }
  };
  return (
    <div className="withdrawal-page">
      <Header headerType="BACK" />
      <div className="base-detail-layout">
        <h2>회원탈퇴 유의사항</h2>
        <p className="desc">
          회원탈퇴 즉시 서비스 이용이 불가하며, <br />
          계정 정보 및 이용내역은 복구할수 없습니다
          <br />
          정말 탈퇴하시겠습니까?
        </p>

        <BaseCheckbox
          id={"agree"}
          name={"agree"}
          checked={agree}
          className="flex-center"
          onChange={() => {
            setAgree((prev) => !prev);
          }}
        >
          <p className="ml10 font16 font-weight-600">[필수] 개인정보 수집 및 이용 동의</p>
        </BaseCheckbox>
      </div>
      {confirmModal.isOpen && (
        <ConfirmModal
          isOpen={true}
          btnRightTitle={"확인"}
          onClick={() => {
            setConfirmModal({ isOpen: false });
          }}
        >
          <h2 className="font18 font-weight-600">
            {confirmModal.type === "S2_CONTRACT_MANAGER" ? "유효한 공간 예약이 있습니다" : "계약담당자는 탈퇴할 수 없습니다"}
          </h2>
          <p className="mt20 font15">
            {confirmModal.type === "S2_CONTRACT_MANAGER"
              ? "예약을 취소하거나 예약이 종료된 후 다시 시도해주세요"
              : "건물 관리소 또는 공간 운영자에게 문의하세요"}
          </p>
        </ConfirmModal>
      )}

      <div className="btn-wrap">
        <BaseButton title="취소" className="base-btn color-white" onClick={() => goBack()}></BaseButton>
        <BaseButton title="회원 탈퇴하기" className="base-btn" disabled={!agree} onClick={handleWithdrawal}></BaseButton>
      </div>
    </div>
  );
};

export default Withdrawal;
