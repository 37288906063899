import { useEffect, useMemo, useState } from "react";
import { getMediasAsync } from "src/api/building/building-api";
import { BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { MediaFile } from "src/api/file/file-types";
import { useApiOperation } from "src/api/hooks";
import { getPublicProductAsync } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";

type Options = {
  productId: string;
  facilityId: string;
};

const useProductFacility = (options: Options) => {
  // path variable 공간상품 id

  const [product, setProduct] = useState<ProductModel>();
  const [facilitys, setFacilitys] = useState<(CommonFacilityModel | undefined)[]>([]);

  const [medias, setMedias] = useState<MediaFile[]>([]);

  // 공용공간 상세
  const facility = useMemo(() => {
    if (!options.facilityId) {
      return;
    }
    return facilitys.find((item) => Number(item?.id) === Number(options.facilityId));
  }, [options.facilityId, facilitys]);

  // 건물 상세
  const building = useMemo(() => {
    if (!facility) {
      return;
    }

    return product?.buildingList?.find((building: BuildingModel) => Number(building.id) === Number(facility.buildingId));
  }, [facility, product?.buildingList]);

  // (무인증) 공간상품 정보 조회 api
  const { executeAsync: getPublicProduct } = useApiOperation(getPublicProductAsync, { noAuthenticationRequired: true });

  // 건물/상품 이미지 조회 api
  const { executeAsync: getMedias } = useApiOperation(getMediasAsync, { noAuthenticationRequired: true });

  // 상품 상세 api 호출
  useEffect(() => {
    if (options?.productId) {
      fetchProduct();
    }
  }, [options?.productId]);

  // 각 빌딩의 공용공간 한 배열로 묶기 [좌석/회의실/편의시설]
  const facilityList = (buildings: BuildingModel[]) => {
    const buildingFacilitys = buildings.flatMap((building) => ({ buildingId: building.id, ...building.buildingCommonFacility }));

    // 회의실
    const meetingRooms = buildingFacilitys.flatMap((facility) => facility?.meetingRoomList) || [];

    // 편의시설
    const refreshRooms = buildingFacilitys.flatMap((facility) => facility?.refreshRoomList) || [];

    // 좌석
    const deskList =
      buildingFacilitys
        .flatMap((facility) => facility?.deskSpace?.deskGroupList)
        ?.filter((group) => group?.deskList && group.deskList.length > 0)
        ?.flatMap((group) => group?.deskList?.map((desk) => ({ ...desk, groupName: group.groupName, mediaList: group.mediaList }))) || [];
    // 공용공간 병합
    const facilityMerged = [
      //
      // ...parkingLots,
      ...meetingRooms,
      ...refreshRooms,
      ...deskList,
    ];
    setFacilitys(facilityMerged || []);
  };

  // 상품상세 조회
  const fetchProduct = async () => {
    const { data, status } = await getPublicProduct({ productId: String(options.productId) });

    if (status >= 200 && status <= 299) {
      const result = data.data.content;
      setProduct(result);

      const productBuildings = data.data.content.buildingList?.map((building) => {
        return building.building ? building.building : {};
      });
      if (productBuildings) {
        facilityList(productBuildings || []);
      }
    }
  };

  //
  const fetchMedias = async (serviceId: string) => {
    const { data, status } = await getMedias({ serviceId, mediaServiceType: "MEDIA_BUILDING" });
    if (status >= 200 && status <= 299) {
      const result = mediaSorting(data.data.content);

      setMedias(result);
    }
  };

  useEffect(() => {
    building && fetchMedias(String(building?.id));
  }, [building]);

  // 이미지 - 대표 이미지가 첫번째로 노출되도록 sorting
  const mediaSorting = (files: MediaFile[]) => {
    const sortedMedias = files.sort((a: MediaFile, b: MediaFile) => {
      // isPrimary가 true인 요소를 맨 앞으로 이동
      if (a.isPrimary && !b.isPrimary) {
        return -1;
      }
      if (!a.isPrimary && b.isPrimary) {
        return 1;
      }
      // isPrimary가 동일하면 orderNums에 따라 정렬
      return a.orderNums! - b.orderNums!;
    });
    return sortedMedias;
  };

  return {
    facility,
    product,
    building: {
      ...building,
      medias,
    },
    mediaSorting,
  };
};

export default useProductFacility;
