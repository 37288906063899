import { atom } from "recoil";

export interface TooltipContainer {
  element: HTMLElement | null;
}

export const tooltipContainerState = atom<TooltipContainer>({
  key: "tooltipContainerState",
  default: {
    element: null,
  },
});
