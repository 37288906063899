import React, { useRef, useState } from "react";
import { BaseButton } from "src/components";
import FileUploadButtonPopup from "./FileUploadButtonPopup";
import Tooltip from "src/pages/components/Tooltip";
import userInviteImg from "src/assets/images/user_invite_img.svg";

interface Props extends React.ComponentProps<typeof BaseButton> {}

function FileUploadButton(props: Props) {
  const [isPopup, setIsPopup] = useState(false);

  const onMouseEnterHandler = () => {
    setIsPopup(true);
  };

  const onMouseLeaveHandler = () => {
    setIsPopup(false);
  };

  const onMouseOverHandler = () => {
    setIsPopup(true);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="ml24 mr24 flex-center-between " ref={containerRef}>
        <div className="center">
          <h1>이용자 초대</h1>
        </div>
        <div className="flex-center mb10">
          {/* <div
            className="excel_upload_info"
            onMouseOver={onMouseOverHandler}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
          ></div> */}
          <Tooltip
            title="대량의 이용자 초대가 필요하세요?"
            children={
              <>
                <div>
                  <p>
                    초대할 이용자들의 휴대폰 번호를
                    <br /> 엑셀 파일로 업로드하세요.
                    <br />
                    <br />
                  </p>
                  <p className="mb10">
                    둘째 행부터 휴대폰 번호를 연속하여 기입해 주세요.
                    <br /> *.xls, xlsx 형태의 엑셀 파일만 지원합니다.
                  </p>
                  <img src={userInviteImg} width="122" height="76" alt="엑셀업로드 이미지" />
                </div>
              </>
            }
            position={"right"}
          />
          <div className="file-upload-button-container">
            <BaseButton title={props.title} onClick={props.onClick} className="ml10 base-btn color-white size-small file-upload-button" />
          </div>
        </div>
      </div>
      {/* {isPopup && (
        <div
          className="tooltip-floating-btn-wrap"
          onMouseOver={onMouseOverHandler}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        >
          <FileUploadButtonPopup isVisible={isPopup} />
        </div>
      )} */}
    </>
  );
}

export default FileUploadButton;
