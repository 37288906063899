import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getBillingContractBillsAsync,
  getBillsPaymentData,
  getContractAsync,
  getContractsAsync,
  getExpenseProofAsync,
  getPaymentData,
  postBillingAuthenticationAsync,
  postExpenseProofAsync,
  postPayletterPayment,
} from "src/api/contract/contract-api";
import { BillModel, BillingContractBillModel, ContractModel, MemberContractModel, isFreeContract } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useMediaQueries } from "src/pages/hooks/media-query";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import usePostMessage from "src/pages/hooks/post-message";
import { useMemberInfoState } from "src/recoil/member/hook";
import { YmdFormat, emailFormat, getUserDevice, linePhoneFormat, validatePhoneNumber } from "src/utils/common-util";
import AccountInfo from "./components/AccountInfo";
import BasicInfo from "./components/BasicInfo";
import ContractInfo from "./components/ContractInfo";
import MyPaymentStatus from "./components/MyPaymentStatus";
import PaymentInfo from "./components/PaymentInfo";

import moment from "moment";
import { ConfirmModal } from "src/components";
import { Modal } from "src/pages/contract/contract-types";
import ServiceTypePaymentInfo from "../serviceTypePayment/ServiceTypePaymentInfo";
import PaymentMethodInfo from "./components/PaymentMethodInfo";
import _ from "lodash";

// 계약상태 - 계약체결 이전까지의 상태들
const contractState = ["CONTRACT_ACCEPT", "USE_APPROVAL", "USE_PROGRESS", "USE_COMPLETE", "TERMINATE_RECEIVED", "TERMINATE_COMPLETE"];

/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 화면
 */
const ContractDetail = () => {
  const { isMobile } = useMediaQueries();

  const locatioin = useLocation();
  const device = getUserDevice();

  const navigate = useNavigate();

  const { goBack } = useNavigateGoBack();

  const { postMessageGoBackNavigation, postWebViewPaymentMessage } = usePostMessage();

  // path variable 계약 id
  const { contractId } = useParams();
  // 로그인한 회원 정보
  const { member } = useMemberInfoState();
  // console.log("member", member);
  // 신청/계약 목록 조회 api
  const { executeAsync: getContracts } = useApiOperation(getContractsAsync);
  // 지출 증빙 자료 저장
  const { executeAsync: saveExpenseProof } = useApiOperation(postExpenseProofAsync);
  // 지출 증빙 자료 조회
  const { executeAsync: getExpenseProof } = useApiOperation(getExpenseProofAsync);

  // 신청/계약 상세 조회 api
  const { executeAsync: getContract } = useApiOperation(getContractAsync);

  // 청구정보 결제현황 조회 api
  const { executeAsync: getBillingContractBills } = useApiOperation(getBillingContractBillsAsync);

  // 신청/계약 신용카드 등록
  const { executeAsync: postBillingAuthentication } = useApiOperation(postBillingAuthenticationAsync);
  // S2 페이레터 결제하기 API { billId, body : {pgcode: string }}
  const { executeAsync: postPayment } = useApiOperation(postPayletterPayment);

  // 상세화면 접근가능한지 여부
  const [isAlowedDetailPage, setIsAlowedDetailPage] = useState<boolean>(false);

  //결제 내역 및 취소네역
  const { executeAsync: fetchPaymentS2List } = useApiOperation(getBillsPaymentData);

  // 신청/계약 상세
  const [contract, setContract] = useState<ContractModel | null>(null);
  // 청구정보 결제현황
  const [billingContractBill, setBillingContractBill] = useState<BillingContractBillModel>();
  // S2 청구정보 결제현황
  const [pList, setPList] = useState<any>([]);

  const [isClicked, setIsClicked] = useState(false);
  const [primaryQuotationName, setPrimaryQuotationName] = useState<any>({});
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
    message: "",
    subMessage: "",
  });
  const [documentProofRequest, setDocumentProofRequest] = useState<any>({
    identityNum: "",
    mediaList: [],
    taxInvoiceEmail: "",
    taxInvoicePhone: "",
    taxInvoiceDate: null,
  });
  const errorInit: any = useMemo(() => {
    return {
      error: {
        identityNum: false,
        identityType: false,
        mediaList: false,
        taxInvoiceEmail: false,
        taxInvoicePhone: false,
        taxInvoiceDate: false,
      },
      format: {
        identityNum: false,
        identityType: false,
        mediaList: false,
        taxInvoiceEmail: false,
        taxInvoicePhone: false,
        taxInvoiceDate: false,
      },
    };
  }, []);
  const [proofError, setProofError] = useState<any>(_.cloneDeep(errorInit));

  // 잔금이 0원인지 여부
  const isZeroBalanceAmount = useMemo(() => contract?.balanceAmount === 0, [contract]);

  // 스케줄 리스트에 rental item이 있는지 확인
  const rentalTypeSchedule = useMemo(() => {
    if (contract && contract.scheduleList.length > 0) {
      const rentalSchedule: any = contract?.scheduleList.find((item) => item.supplyType === "RENTAL");
      if (rentalSchedule) {
        return rentalSchedule;
      }
    }
  }, [contract]);
  // 무료 여부
  const isFree = useMemo(() => {
    return isFreeContract(contract);
  }, [contract]);
  // 신용캬드 등록 및 노출 여부
  const isPaymentCardExport = useMemo(() => {
    let _isPaymentCardExport = false;

    console.log("rentalTypeSchedule", rentalTypeSchedule, contract);

    const allowedContractState = contractState.some((state) => state === contract?.contractStep);

    // billkey 여부 확인 - billKey 가 있으면 신용카드 이미 등록상태
    const isBillkey = rentalTypeSchedule?.isBillkey;

    //체크
    // console.log("allowedContractState", allowedContractState);
    // console.log("isBillkey", isBillkey);
    // console.log("rentalTypeSchedule?.pgcode", rentalTypeSchedule?.regularPgcode);
    // console.log("isFree", isFree);

    if (
      allowedContractState && //
      !isBillkey &&
      rentalTypeSchedule?.regularPgcode === "creditcard" &&
      isFree !== null &&
      !isFree
      // rentalTypeSchedule?.contractPayType === "PAY"&&
    ) {
      _isPaymentCardExport = true;
    }
    return _isPaymentCardExport;
  }, [contract, rentalTypeSchedule, isFree]);

  // 계좌 정보 노출여부 - 이용승인 / 이용중 / 해지접수 상태 시 노출 X
  const isAccountExport = useMemo(() => {
    let _isAccountExport = true;

    // 결제카드 등록 상태 boolean
    const isBillkey = rentalTypeSchedule?.isBillkey;

    if (isBillkey === true) {
      switch (contract?.contractStep) {
        case "USE_APPROVAL":
          _isAccountExport = false;
          break;
        case "USE_PROGRESS":
          _isAccountExport = false;
          break;
        case "TERMINATE_RECEIVED":
          _isAccountExport = false;
          break;
      }
    }
    return _isAccountExport;
  }, [contract?.contractStep, rentalTypeSchedule?.isBillkey]);

  // 결제 수단 변경 불가 여부 (해지접수 || 해지완료 상태일 때 비활성)
  const disabledPaymentChange = useMemo(() => {
    let disabled = false;

    if (contract?.contractStep === "USE_COMPLETE" || contract?.contractStep === "TERMINATE_COMPLETE") {
      disabled = true;
    }
    return disabled;
  }, [contract?.contractStep]);

  // 보증금 (1회차 원청구) 청구서
  const depositBill = useMemo(() => {
    if (!billingContractBill || (billingContractBill?.billList || []).length === 0) return null;
    return (billingContractBill?.billList || []).find((bill: BillModel) => bill.billOrder === 1 && !bill?.originBill.isOverdueBill);
  }, [billingContractBill]);

  const isZeroDepositBill = useMemo(() => {
    if (!depositBill) return false;
    return depositBill?.originBill.totalAmount === 0;
  }, [depositBill]);

  // 로그인한 사용자의 신청/계약 목록을 조회해서 상세화면 접근가능한 사용자 인지 여부 값 바인딩
  const fetchIsAlowedDetailPage = useCallback(
    async (contractId: string, memberNo: string) => {
      const { data } = await getContracts({
        memberNo,
      });
      const contracts: Array<MemberContractModel> = data.data.content || [];
      // 로그인한 사용자의 신청/계약 목록 중, 계약자인 것들 중, 해당 계약이 있는지 확인
      const findContract = contracts.find((contract) => contract.isContractor && String(contract.contractId) === String(contractId));
      const _isAlowedDetailPage = !!findContract;
      if (_isAlowedDetailPage) {
        setIsAlowedDetailPage(true);
      } else {
        navigate("/court/error/page-not-allowed", { replace: true });
      }
    },
    [getContracts],
  );

  // 신청/계약 상세 조회
  const fetchContract = useCallback(
    async (contractId: string) => {
      const { data } = await getContract({ contractId });
      const { data: data2 } = await getExpenseProof({ contractId });

      const contract = data?.data || null;
      setContract(contract);

      if (data2.data.expenseProofRequest.mediaList !== null) {
        data2.data.expenseProofRequest.mediaList.forEach((dt: any) => {
          dt["cmdType"] = "U";
        });
        setDocumentProofRequest(data2.data.expenseProofRequest);
      }
    },
    [getContract, getExpenseProof],
  );

  // 청구정보 결제현황 조회
  const fetchBillingContractBills = useCallback(
    async (contractId: number) => {
      const { data } = await getBillingContractBills({ contractId });
      setBillingContractBill(data?.data);
    },
    [getBillingContractBills],
  );

  // 신용카드 결제등록 url 조회
  const fetchPaymentUrl = async () => {
    const rentalType = contract?.scheduleList.find((item) => item.supplyType === "RENTAL");
    if (contract && rentalType) {
      let envType = "web";
      if (window.ReactNativeWebView) {
        envType = "taap";
      }
      const params: any = { scheduleId: String(rentalType.scheduleId), envType };
      // console.log("params", params);
      const { data } = await postBillingAuthentication(params);
      // console.log("data", data);
      if (data.data) {
        if (window.ReactNativeWebView) {
          postWebViewPaymentMessage(data.data.mobileUrl);
        } else {
          if (isMobile) {
            // 새창 없이 그대로 페이지 이동
            window.location.href = data.data.mobileUrl;
          } else {
            // 새창 없이 그대로 페이지 이동
            window.location.href = data.data.onlineUrl;
          }
        }
      }
    }
  };

  //세금계싼서 벨리데이션 체크
  const validationChecker = useCallback(
    (documentProofRequest: any) => {
      let rtnFlag = true;
      const check = _.cloneDeep(errorInit);
      console.log("documentProofRequest.expenseProofType", documentProofRequest);
      if (documentProofRequest.expenseProofType === "TAX_INVOICE") {
        if (documentProofRequest.identityNum === null || documentProofRequest.identityNum === "") {
          console.log("identityNum");
          rtnFlag = false;
          check.error.identityNum = true;
          check.format.identityNum = false;
        } else {
          console.log(
            "documentProofRequest.identityNum",
            documentProofRequest.identityNum,
            documentProofRequest.identityNum.length,
            documentProofRequest.identityNum.length === 10,
          );
          check.error.identityNum = false;
          if (documentProofRequest.identityNum.length === 10) {
            console.log("정상");
            check.format.identityNum = false;
            check.error.identityNum = false;
          } else {
            console.log("비정상");
            check.format.identityNum = true;
            check.error.identityNum = false;
            rtnFlag = false;
          }
        }
        console.log("rtnFlag identityNum", rtnFlag);
        if (documentProofRequest.mediaList.length === 0) {
          console.log("mediaList");
          rtnFlag = false;
          check.error.mediaList = true;
        } else {
          check.error.mediaList = false;
        }
        if (documentProofRequest.taxInvoiceEmail === null || documentProofRequest.taxInvoiceEmail === "") {
          console.log("taxInvoiceEmail");
          rtnFlag = false;
          check.error.taxInvoiceEmail = true;
        } else {
          check.error.taxInvoiceEmail = false;
          if (!emailFormat(documentProofRequest.taxInvoiceEmail)) {
            rtnFlag = false;
            check.format.identityNum = true;
          } else {
            check.format.identityNum = false;
          }
        }
        console.log("rtnFlag taxInvoiceEmail", rtnFlag);
        if (documentProofRequest.taxInvoicePhone === null || documentProofRequest.taxInvoicePhone === "") {
          console.log("taxInvoicePhone");
          rtnFlag = false;
          check.error.taxInvoicePhone = true;
        } else {
          check.error.taxInvoicePhone = false;
          if (!linePhoneFormat(documentProofRequest.taxInvoicePhone)) {
            rtnFlag = false;
            check.format.identityNum = true;
          } else {
            check.format.identityNum = false;
          }
        }
        console.log("rtnFlag taxInvoicePhone", rtnFlag);
        if (documentProofRequest.taxInvoiceDate === null || documentProofRequest.taxInvoiceDate === "") {
          console.log("taxInvoiceDate");
          rtnFlag = false;
          check.error.taxInvoiceDate = true;
        } else {
          check.error.taxInvoiceDate = false;
        }
        console.log("rtnFlag taxInvoiceDate", rtnFlag);
      }

      setProofError({ ...check });
      return rtnFlag;
    },
    [errorInit, setProofError],
  );

  const sendPgCode = async (pgcode: string) => {
    const rentalType = contract?.scheduleList.find((item) => item.supplyType === "RENTAL");
    if (contract && rentalType) {
      let billId = rentalType.recentBill?.billId!;
      let envType = "web";
      if (window.ReactNativeWebView) {
        envType = "taap";
      }
      const params: any = { billId, pgcode, envType };
      const { data } = await postPayment(params);
      if (data.data) {
        if (window.ReactNativeWebView) {
          postWebViewPaymentMessage(data.data.mobileUrl);
        } else {
          if (isMobile) {
            // 새창 없이 그대로 페이지 이동
            window.location.href = data.data.mobileUrl;
          } else {
            // 새창 없이 그대로 페이지 이동
            window.location.href = data.data.onlineUrl;
          }
        }
      }
    }
  };

  // 신용카드 결제 or 가상계좌 url 조회
  const fetchPaymentUrlS2 = async (pgcode: string, documentProofRequest: any) => {
    const checkData = _.cloneDeep(documentProofRequest);
    let result = true;
    if (pgcode !== "creditcard") {
      result = validationChecker(checkData);
      if (result === true) {
        if (checkData.taxInvoiceDate !== null && checkData.taxInvoiceDate !== "") {
          checkData.taxInvoiceDate = moment(checkData.taxInvoiceDate).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
        }

        if (checkData.contractId !== null && Number(checkData.contractId) > 0) {
          checkData.cmdType = "U";
        } else {
          checkData.cmdType = "C";
        }
        if (pgcode === "creditcard") {
          checkData.isDeleted = true;
        }
        if (checkData.mediaList.length > 0) {
          checkData.mediaList.forEach((media: any) => {
            // console.log("media.blobUrl", media.blobUrl);
            delete media.blobUrl;
          });
        }
      }
    }
    //지출 증비 관련 저장

    console.log("result", result);
    if (result === true) {
      setProofError({ ...errorInit });
      if (pgcode !== "creditcard") {
        const saveExpense = await saveExpenseProof({ contractId: String(contract!.contractId), expenseProofRequest: checkData });
        if (saveExpense.status > 199 && saveExpense.status < 400) {
          await sendPgCode(pgcode);
        }
      } else {
        await sendPgCode(pgcode);
      }
    }
    // console.log("pgcode", pgcode, "documentProofRequest", documentProofRequest);
  };

  useEffect(() => {
    window.court.webViewFunction.onPaymentCompleted = (queryParam?: string) => {
      const param: { [prop: string]: string } = JSON.parse(queryParam || "{}");

      const searchParams = new URLSearchParams(param).toString();
      let path = "/court/payment/result";

      if (searchParams) {
        path += `?${searchParams}`;
      }

      navigate(path, {
        replace: true,
      });
    };
  }, []);

  useEffect(() => {
    if (contractId && member && member.memberNo) {
      // 로그인한 사용자의 신청/계약 목록을 조회해서 상세화면 접근가능한 사용자 인지 여부 값 바인딩
      fetchIsAlowedDetailPage(contractId, member.memberNo);
    }
  }, [contractId, member, fetchIsAlowedDetailPage]);

  useEffect(() => {
    // 상세화면 접근가능할 경우 조회
    if (isAlowedDetailPage && contractId) {
      // 신청/계약 상세 조회
      fetchContract(contractId);
      fetchBillingContractBills(Number(contractId));
    }
  }, [isAlowedDetailPage, contractId, fetchContract, fetchBillingContractBills]);

  useEffect(() => {
    // 유료일 경우에만 조회
    if (contract && contract?.contractId && !isFree) {
      // 청구정보 결제현황 조회
      fetchBillingContractBills(contract.contractId);
    }
  }, [contract, isFree, fetchBillingContractBills]);

  // 버튼 클릭시 더블클릭 방지
  const handleClickPayment = () => {
    // 이미 클릭되었다면 더블 클릭을 방지
    if (isClicked) {
      return;
    }

    // 버튼 클릭 처리
    setIsClicked(true);

    // 클릭 이후 1.5초 동안 다시 클릭 불가능하도록 설정
    setTimeout(() => {
      setIsClicked(false);
    }, 1500);

    fetchPaymentUrl();
  };
  // 버튼 클릭시 더블클릭 방지
  const handleClickPaymentS2 = (pgcode: string, documentProofRequest: any) => {
    // 이미 클릭되었다면 더블 클릭을 방지
    if (isClicked) {
      return;
    }

    // 버튼 클릭 처리
    setIsClicked(true);

    // 클릭 이후 1.5초 동안 다시 클릭 불가능하도록 설정
    setTimeout(() => {
      setIsClicked(false);
    }, 1500);

    fetchPaymentUrlS2(pgcode, documentProofRequest);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paymentStatus, setPaymentStatus] = useState<any>([]);
  const { executeAsync: fetchPaymentData } = useApiOperation(getPaymentData);

  const paymentlist = useCallback(
    async (contractId: string) => {
      const response = await fetchPaymentData({ contractId });
      if (response.data.data.content && response.data.data.content.length > 0) {
        setPaymentStatus(response.data.data.content);
      }
    },
    [fetchPaymentData],
  );
  const handleClickExpenseProofCashBill = useCallback(
    async (handleClickExpenseProof: any) => {
      if (documentProofRequest.taxInvoiceDate !== null && documentProofRequest.taxInvoiceDate !== "") {
        documentProofRequest.taxInvoiceDate = moment(documentProofRequest.taxInvoiceDate).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
      }

      if (documentProofRequest.contractId !== null && Number(documentProofRequest.contractId) > 0) {
        documentProofRequest.cmdType = "U";
      } else {
        documentProofRequest.cmdType = "C";
      }

      const saveExpense = await saveExpenseProof({ contractId: String(contract!.contractId), expenseProofRequest: documentProofRequest });
      if (saveExpense.status > 199 && saveExpense.status < 400) {
        setAlertModal({
          ...alertModal,
          isOpen: true,
          message: "현금영수증 발행 요청을 완료하였습니다.",
          subMessage: "요청하신 정보로 현금영수증 발행이 접수되었습니다.",
        });
      }
    },
    [alertModal, contract, documentProofRequest, saveExpenseProof],
  );

  const findS2Payments = useCallback(
    async (billId: string, pgcode: string) => {
      let isSuccessOnly = true;
      if (pgcode === "virtualaccount") {
        isSuccessOnly = false;
      }
      const response = await fetchPaymentS2List({ billId, pgcode, isSuccessOnly });
      if (response.data.data.content) {
        setPList(response.data.data.content);
      }
    },
    [fetchPaymentS2List],
  );

  useEffect(() => {
    if (contract) {
      paymentlist(String(contract?.contractId));
      if (rentalTypeSchedule?.recentBill !== null) {
        findS2Payments(rentalTypeSchedule?.recentBill?.billId!, rentalTypeSchedule?.recentBill?.pgcode!);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  // console.log(!isZeroDepositBill, "1월 원청구가 0원이 아닐때");
  // console.log(!isZeroBalanceAmount, "잔금이 0원?");
  return (
    <>
      <MetaTag title="마이페이지" />
      <Header
        headerType="CLOSE"
        // title="상세 내역"
        title="신청 / 계약"
        onClickCloseButton={() => {
          //리액트 네이티브 웹뷰가 아니면 state 체크해서 -1 처리 or 신청/계약 목록으로 이동 처리
          if (!window.ReactNativeWebView) {
            if (locatioin.state && locatioin.state === "mypage") {
              navigate("/court/mypage/contracts");
            } else {
              goBack();
            }
          } else {
            postMessageGoBackNavigation();
          }
        }}
      />

      <div className="contract-page">
        <h1 className="base-title">신청/계약</h1>
        <article className="with-backward-floating">
          {/* 결제수단 */}
          {contract?.spaceProductType !== "TIME_COURT" && isPaymentCardExport && <PaymentInfo handleClickPayment={handleClickPayment} />}
          {/* 기본정보 */}
          <div className={contract?.spaceProductType !== "TIME_COURT" && isPaymentCardExport ? "top-divider" : ""}>
            <BasicInfo contract={contract} fetchContract={fetchContract} setPrimaryQuotationName={setPrimaryQuotationName} />
          </div>

          {/* 계약 정보 */}
          <ContractInfo contract={contract} />

          {/* 계좌 정보 */}
          {contract?.spaceProductType !== "TIME_COURT" &&
            // {/* 나의 납부상태 */}
            !isZeroDepositBill &&
            (isZeroBalanceAmount ? (
              <MyPaymentStatus contract={contract} billingContractBill={billingContractBill} />
            ) : (
              <>
                {/* 이용승인 상태 이후 && 신용카드 등록되어있을 시 계좌정보 노출 X */}
                {isAccountExport && <AccountInfo contract={contract} billingContractBill={billingContractBill} />}
                <MyPaymentStatus contract={contract} billingContractBill={billingContractBill} />
              </>
            ))}

          {/* 결제수단 */}

          {contract?.spaceProductType !== "TIME_COURT" && rentalTypeSchedule?.isBillkey && (
            <PaymentMethodInfo
              scheduleInfo={rentalTypeSchedule}
              handleClickPayment={handleClickPayment}
              disabledPaymentChange={disabledPaymentChange}
            />
          )}

          {contract?.spaceProductType === "TIME_COURT" && rentalTypeSchedule?.recentBill && (
            <ServiceTypePaymentInfo
              contract={contract}
              paymentStatus={pList}
              primaryQuotationName={primaryQuotationName}
              recentBill={rentalTypeSchedule?.recentBill}
              handleClickPaymentS2={handleClickPaymentS2}
              documentProofRequest={documentProofRequest}
              setDocumentProofRequest={setDocumentProofRequest}
              billingContractBill={billingContractBill}
              handleClickExpenseProofCashBill={handleClickExpenseProofCashBill}
              proofError={proofError}
              setProofError={setProofError}
            />
          )}

          {/* 한컴타운 코워킹 연동 (모바일 기기에만 출력) */}
          {/* {device !== "other" && <HancomSpace contract={contract} />} */}
        </article>
        {alertModal.isOpen && (
          <ConfirmModal
            isOpen={alertModal.isOpen}
            btnRightTitle={"확인"}
            onClick={() => {
              setAlertModal({ ...alertModal, isOpen: false });

              window.location.reload();
            }}
          >
            <div>
              <p className="font18 font-weight-600">{alertModal?.message}</p>
              <p className="font18">{alertModal?.subMessage}</p>
            </div>
          </ConfirmModal>
        )}
        {/* 버튼 상태별 노출 (1.1 버전 스펙아웃) */}
        {/* <BottomButton contract={contract} selectedBillIndex={selectedBillIndex} /> */}
      </div>
    </>
  );
};

export default ContractDetail;
