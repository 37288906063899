import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBuildingsDetailsAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { getQuotationList, getRefundInfo, patchContractCancelAsync } from "src/api/contract/contract-api";
import { ContractModel, Quotation, changeStatusText } from "src/api/contract/contract-types";
import { getPublicMediaFilesAsync } from "src/api/file/file-api";
import { MediaFile } from "src/api/file/file-types";
import { useApiOperation } from "src/api/hooks";
import { getProductAsync } from "src/api/product/product-api";
import { Address } from "src/api/public-types";
import noimg from "src/assets/images/icons_large_noimg.svg";
import { ConfirmModal } from "src/components";
import BaseFullModal from "src/components/BaseFullModal";
import { getResizedImageUrl, getUseTimeDiff, makeCommonSpaceList, YmdFormat } from "src/utils/common-util";
import ContractCancel from "./ContractCancel";
import Tooltip from "src/pages/components/Tooltip";

type Props = {
  contract?: ContractModel | null;
  fetchContract: (contractId: string) => void;
  setPrimaryQuotationName?: any;
};

type Modal = {
  isOpen: boolean;
  message?: string;
  type?: string;
};

/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 화면 > 기본 정보
 */
const BasicInfo = ({ contract, fetchContract, setPrimaryQuotationName }: Props) => {
  const navigate = useNavigate();
  const [isPopup, setIsPopup] = useState(false);

  const onMouseEnterHandler = () => {
    setIsPopup(true);
  };

  const onMouseLeaveHandler = () => {
    setIsPopup(false);
  };

  const onMouseOverHandler = () => {
    setIsPopup(true);
  };

  // (무인증) 건물 상세정보 목록 api
  const { executeAsync: getBuildingsDetails } = useApiOperation(getBuildingsDetailsAsync, { noAuthenticationRequired: true });

  const { executeAsync: findSnapshot } = useApiOperation(getRefundInfo);

  // (무인증) 공통 미디어파일 목록 api
  const { executeAsync: getPublicMediaFiles } = useApiOperation(getPublicMediaFilesAsync, { noAuthenticationRequired: true, noHandleError: true });

  // 이용신청 취소 api
  const { executeAsync: patchContractCancel } = useApiOperation(patchContractCancelAsync, { noHandleError: true });

  // 계약 견적서 조회 api
  const { executeAsync: patchContractQuotations } = useApiOperation(getQuotationList);
  // 상품의 건물 데이터 중 공용공간 매핑을 위해 조회 API
  const { executeAsync: patchContractProduct } = useApiOperation(getProductAsync);

  // 건물 상세
  const [building, setBuilding] = useState<BuildingModel | null>(null);

  // 공간상품 대표 이미지
  const [primaryMediaFile, setPrimaryMediaFile] = useState<MediaFile>();
  // 신청/계약 취소 상태 변경 시
  const [cancelCheck, setCancelCheck] = useState(false);

  // 확인 취소 버튼 있는 확인 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  // 확인 버튼만 있는 알림 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const [quotationCustomList, setQuotationCustomList] = useState<any[]>([]);

  const [refundInfo, setRefundInfo] = useState<any>(null);
  const [guideInfo, setGuideInfo] = useState<any>(null);

  // 계약예정 후의 상테 (신청접수 / 신청취소 / 신청확인 상태는 제외)
  const isAfterApplyContractStatus = useMemo(
    () =>
      ["CONTRACT_PLAN", "CONTRACT_ACCEPT", "USE_APPROVAL", "USE_PROGRESS", "USE_COMPLETE", "TERMINATE_RECEIVED", "TERMINATE_COMPLETE"].includes(
        contract?.contractStep || "",
      ),
    [contract?.contractStep],
  );

  const rentalSchedule = useMemo(() => {
    return contract?.scheduleList.find((schedule: any) => schedule.supplyType === "RENTAL");
  }, [contract]);

  // 권한이 유효한 상태 (이용중 / 해지접수) Boolean 리턴
  const isAuthorizedStatus = useMemo(() => ["USE_PROGRESS", "TERMINATE_RECEIVED"].includes(contract?.contractStep || ""), [contract]);
  // 건물 주소
  const buildingAddress: string = useMemo(() => {
    if (!building) return "";
    const address: Address | undefined = (building?.addressList || [])[0];
    return address ? `${address?.address} ${address?.addressDetail}` : "";
  }, [building]);

  //계약 상품의 환불정보 조회
  const fetchFindInfo = useCallback(
    async (contractId: string) => {
      console.log("contractId", contractId);
      const _guideInfo = await findSnapshot({ contractId: contractId, domain: "product", attrName: "productNotice" } as any);
      if (_guideInfo.status === 200 && _guideInfo.data.data && _guideInfo.data.data.content && _guideInfo.data.data.content.length > 0) {
        setGuideInfo(_guideInfo.data.data.content[0]);
      }
      const _refundInfo = await findSnapshot({ contractId: contractId, domain: "product", attrName: "refundInfo" } as any);
      console.log("_refundInfo.data.data.content[0]", _refundInfo.data.data.content[0]);
      if (_refundInfo.status === 200 && _refundInfo.data.data && _refundInfo.data.data.content && _refundInfo.data.data.content.length > 0) {
        setRefundInfo(_refundInfo.data.data.content[0]);
      }
    },
    [findSnapshot],
  );

  // 건물 이미지 없을때 noimg 적용
  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = noimg;
  };

  //상품 조회
  const getContractProductData = useCallback(
    async (productId: string) => {
      // console.log("productId", productId);
      const response = await patchContractProduct({ productId });
      if (response.data.data.content) {
        return response.data.data.content;
      }
    },
    [patchContractProduct],
  );

  // 신청 취소
  const contractCancel = useCallback(async () => {
    if (!contract?.contractId) {
      throw Error("contract?.contractId is not found");
    }
    const { data } = await patchContractCancel({ contractId: contract?.contractId });
    if (data?.data?.contractId) {
      await fetchContract(String(contract.contractId));
      setAlertModal({ isOpen: true, message: "신청 취소가 완료되었습니다." });
    }
  }, [patchContractCancel, contract, fetchContract]);

  // Modal 확인 버튼 클릭
  const clickModalConfirm = useCallback(async () => {
    setConfirmModal({ isOpen: false });
    setCancelCheck(false);
    if (confirmModal.type === "CONTRACT_CANCEL") {
      await contractCancel();
    }
  }, [confirmModal, contractCancel]);

  // 건물 상세정보 조회
  const fetchBuildingsDetail = useCallback(
    async (buildingId: string | number) => {
      const { data } = await getBuildingsDetails({
        buildingIds: [buildingId],
      });
      if (data?.data?.content && (data?.data?.content || []).length > 0) {
        setBuilding((data?.data?.content || [])[0]?.building);
      }
    },
    [getBuildingsDetails],
  );

  // 공통 미디어파일 목록 조회
  const fetchPublicMediaFiles = useCallback(
    async (buildingId: string | number) => {
      const { data } = await getPublicMediaFiles({
        serviceId: buildingId,
        mediaServiceTypes: ["MEDIA_BUILDING"], // 건물 외관 ( 건물 대표 카테고리 )
      });
      if (data?.data?.content && (data?.data?.content || []).length > 0) {
        const _primaryMediaFile = data.data.content.find((v: MediaFile) => v.isPrimary === true);
        if (_primaryMediaFile) {
          setPrimaryMediaFile(_primaryMediaFile);
        }
      }
    },
    [getPublicMediaFiles],
  );

  const cancelTitle = useMemo(() => {
    if (contract?.contractStep === "USE_APPROVAL") {
      const contractStartTime = moment(contract.contractStartTime);
      const today = moment();
      const divide = moment.duration(moment(contractStartTime).diff(today)).asHours();

      if (divide < 24) {
        return "취소불가";
      }
    }
    return "취소";
  }, [contract]);

  const changeContractCancel = useCallback(() => {
    //납부 완료 상태에서는 취소 요청 팝업이 열리고
    if (rentalSchedule && rentalSchedule.recentBill && rentalSchedule.recentBill.billPayStatus === "PAYMENT_SUCCESS") {
      //계약진행단계(이용승인) & 이용시작일시 기준 24시간 이내
      if (contract?.contractStep === "USE_PROGRESS" || contract?.contractStep === "USE_COMPLETE") {
        const am = _.cloneDeep(alertModal);
        am.isOpen = true;
        am.message = "이용이 시작되거나 완료된 예약은 취소할 수 없습니다.";
        setAlertModal(am);
        return;
      }
      if (contract?.contractStep === "USE_APPROVAL") {
        const contractStartTime = moment(contract.contractStartTime);
        const today = moment();
        const divide = moment.duration(moment(contractStartTime).diff(today)).asHours();
        console.log(divide, "divide");
        if (divide < 24) {
          return;
        }
      }
      setCancelCheck(true);
    } else {
      //납부 완료 상태가 아니면 신청 계약 취소 호출
      setConfirmModal({ isOpen: true, message: "이용 신청을 취소하시겠어요?", type: "CONTRACT_CANCEL" });
    }
  }, [alertModal, contract, rentalSchedule]);

  // 계약 견적서 조회
  const contractQuotationList = useCallback(
    async (quotationList: any) => {
      console.log("quotationList", quotationList);
      //S2 계약의 견적서 id 정리
      let ids: string = "";
      let orderNumSorted = _.sortBy(quotationList, ["orderNum"]).reverse();

      orderNumSorted!.forEach((list: Quotation) => {
        if (ids !== "") {
          ids = ids + "," + list.quotationNo;
        } else {
          ids = list.quotationNo;
        }
      });

      const response: any = await patchContractQuotations({ quotationNo: ids });
      let quotationCustom: any = [];
      response.data.data.content.forEach((quotation: any) => {
        quotation.quotationItemList.forEach((itemList: any) => {
          quotationCustom.push(itemList);
        });
      });

      quotationCustom.forEach(async (item: any) => {
        let products: any = {};
        //건물의 공용공간 조회
        products = await getContractProductData(item.productId!);

        const fcs = makeCommonSpaceList(products);
        // console.log("fcs", fcs);
        if (fcs.length > 0) {
          const fc = fcs.find((facility: any) => facility.id === item.buildingCommonFacilityId);
          if (fc) {
            item.facility = fc;
            // console.log(fc, item);
          }
        }
      });

      setPrimaryQuotationName(quotationCustom[0]);
      setQuotationCustomList(quotationCustom);

      //견적서의 공용공간으로 건물의 공용공간 중 매핑되는 것을 처리

      //
    },
    [getContractProductData, patchContractQuotations, setPrimaryQuotationName],
  );

  useEffect(() => {
    // console.log("contract", contract);

    if (contract?.spaceProductType !== "TIME_COURT") {
      if (contract && contract.buildingId) {
        // 건물 상세정보 조회
        fetchBuildingsDetail(contract.buildingId);

        // 공통 미디어파일 목록 조회
        fetchPublicMediaFiles(contract.buildingId);
      }
    } else {
      contractQuotationList(contract?.quotationList);
    }
    if (contract?.contractId) {
      fetchFindInfo(String(contract.contractId));
    }
  }, [contract, fetchBuildingsDetail, fetchPublicMediaFiles, contractQuotationList, fetchFindInfo]);

  const findPrimaryImage = (quotation: any) => {
    let url = "";
    if (
      quotation.facility &&
      quotation.facility.mediaList &&
      Array.isArray(quotation.facility.mediaList) &&
      quotation.facility.mediaList.length > 0
    ) {
      const findPrimary = quotation.facility.mediaList.find((media: any) => media.isPrimary);
      if (findPrimary) {
        url = findPrimary.url;
      } else {
        url = quotation.facility.mediaList[0].url;
      }
    }
    return url;
  };

  return (
    <section className="contract-main">
      <div className="contract-main__index-wrap">
        <p>기본 정보</p>
        <div className={`base-chip ${["USE_APPROVAL", "USE_PROGRESS"].includes(contract?.contractStep || "") ? "" : "color-gray"}`}>
          {changeStatusText(contract?.spaceProductType!, contract?.contractStep!)}
        </div>
      </div>
      <div className="base-section-layout__container">
        <div className="base-section-layout__wrap">
          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3">신청번호</p>
            <div className="flex-row flex-center-end">
              <p className="base-section-layout-value">{contract?.contractApplyNumber}</p>
              {contract?.spaceProductType === "TIME_COURT" && ["CONTRACT_PLAN", "CONTRACT_ACCEPT", "USE_APPROVAL"].includes(contract?.contractStep!) && (
                <div className="flex-center-end">
                  {cancelTitle !== "취소불가" ? (
                    <p className="base-section-layout-value ml10 text-primary3 cursor-pointer" onClick={() => changeContractCancel()}>
                      {cancelTitle}
                    </p>
                  ) : (
                    <p className="base-section-layout-value ml10 text-primary3">{cancelTitle}</p>
                  )}
                  {cancelTitle === "취소불가" && (
                    <Tooltip title="취소 불가" children={<p>이용 시작 24시간 전까지만 취소 가능합니다.</p>} position={"right"} />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3">신청일시</p>
            <p className="base-section-layout-value">{moment(contract?.createdDate).format("YYYY-MM-DD HH:mm")}</p>
          </div>
        </div>
      </div>
      {contract?.spaceProductType !== "TIME_COURT" && (
        <div className="gray-card">
          <div className="gray-card__product">
            <img className="minmax60" src={getResizedImageUrl(primaryMediaFile?.url)} onError={handleImgError} alt="product-img" />
            <div className="gray-card__contents">
              <p className="gray-card__contents-title">{contract?.spaceProductName}</p>
              <p className="gray-card__contents-address">{buildingAddress}</p>
            </div>
          </div>
          <div className="gray-card__info">
            <div className="base-section-layout-line">
              <p className="base-section-layout-index text-primary3">이용 기간</p>
              <p className="base-section-layout-value">
                {contract?.contractStartTime && moment(contract?.contractStartTime).format("YYYY-MM-DD")} ~{" "}
                {contract?.contractEndTime && moment(contract?.contractEndTime).format("YYYY-MM-DD")}
              </p>
            </div>

            {/* F/O 타입이고, 계약예정, 계약체결, 이용승인, 이용중, 이용완료, 해지접수, 해지완료 상태일때 노출 (계약예정이후) */}
            {/* {(contract?.spaceProductType === "FULL_COURT" || contract?.spaceProductType === "OPEN_COURT") && isAfterApplyContractStatus && (
            <div className="base-section-layout-line">
              <p className="base-section-layout-index text-primary3">이용자</p>
              {["CONTRACT_ACCEPT", "USE_APPROVAL", "USE_PROGRESS", "TERMINATE_RECEIVED"].includes(contract?.contractStep || "") ? (
                <button
                  type="button"
                  className="icon-right-btn ic-chevron-right"
                  onClick={() => {
                    navigate(`/court/mypage/contracts/${contract.contractId}/users`);
                  }}
                >
                  <b className="font-family-roboto">
                    {Number(contract?.memberTotCount || 0)}/{Number(contract?.memberMaxNums || 0)}
                  </b>{" "}
                  명
                </button>
              ) : (
                <div className="flex-center font-family-roboto font14 font-weight-400">
                  <p>
                    <b>
                      {Number(contract?.memberTotCount || 0)}/{Number(contract?.memberMaxNums || 0)}
                    </b>{" "}
                    명
                  </p>
                </div>
              )}
            </div>
          )} */}
          </div>
        </div>
      )}

      {Array.isArray(quotationCustomList) &&
        quotationCustomList?.map((quotation: any, index: number) => {
          return (
            <div className="gray-card" key={index}>
              <div className="gray-card__product">
                <img className="minmax60" src={getResizedImageUrl(findPrimaryImage(quotation!))} onError={handleImgError} alt="product-img" />
                <div className="gray-card__contents">
                  <p className="gray-card__contents-title">{contract?.spaceProductName}</p>
                  <p className="gray-card__contents-address">{quotation?.facility?.facilityName!}</p>
                </div>
              </div>
              <div className="gray-card__info">
                <div className="base-section-layout-line">
                  <p className="base-section-layout-index text-primary3">이용 일자</p>
                  <p className="base-section-layout-value">{quotation?.startDate && moment(quotation?.startDate!).format("YYYY-MM-DD")}</p>
                </div>
                <div className="base-section-layout-line">
                  <p className="base-section-layout-index text-primary3">이용 시간</p>
                  <p className="base-section-layout-value">
                    {quotation?.startDate && moment(quotation?.startDate!).format("HH:mm")} ~{" "}
                    {quotation?.endDate && moment(quotation?.endDate!).format("HH:mm")} {getUseTimeDiff(quotation?.startDate!, quotation?.endDate!)}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      {contract?.spaceProductType !== "TIME_COURT" && isAfterApplyContractStatus && (
        <div className="flex-center mt20">
          <button
            className="base-btn color-white size-small mr10"
            disabled={!isAuthorizedStatus}
            onClick={() => navigate(`/court/mypage/contracts/${contract?.contractId}/facilityTime`)}
          >
            공용공간 이용시간
          </button>

          {/* F/O 타입이고, 계약예정, 계약체결, 이용승인, 이용중, 이용완료, 해지접수, 해지완료 상태일때 노출 (계약예정이후) */}

          <>
            {
              <button
                type="button"
                className="base-btn color-white size-small"
                disabled={!["CONTRACT_ACCEPT", "USE_APPROVAL", "USE_PROGRESS", "TERMINATE_RECEIVED"].includes(contract?.contractStep || "")}
                onClick={() => {
                  // 이용자 목록 화면으로 이동
                  navigate(`/court/mypage/contracts/${contract?.contractId}/users`);
                }}
              >
                <b className="font-family-roboto">{`이용자 (${Number(contract?.memberTotCount || 0)}/${Number(contract?.memberMaxNums || 0)})`}</b> 명
              </button>
            }
          </>
        </div>
      )}
      {/*  F/O 타입: 상태값  신청접수, 신청확인 상태이고 계약 종료 일시가 현재일보다 미래일때만 출력 */}
      {contract?.spaceProductType !== "TIME_COURT" &&
        ["APPLY_RECEIVED", "APPLY_CONFIRM"].includes(contract?.contractStep || "") &&
        moment(contract?.contractEndTime).isAfter(moment()) && (
          <div className="pt20">
            <button
              type="button"
              className="base-btn color-white size-small"
              onClick={() => {
                setConfirmModal({ isOpen: true, message: "이용 신청을 취소하시겠어요?", type: "CONTRACT_CANCEL" });
              }}
            >
              취소 요청
            </button>
          </div>
        )}
      {cancelCheck && (
        <BaseFullModal
          isOpen={cancelCheck}
          children={
            <ContractCancel
              refundInfo={refundInfo}
              recentBill={rentalSchedule?.recentBill!}
              onClose={() => setCancelCheck(false)}
              onClick={() => {
                setConfirmModal({ isOpen: true, message: "이용 신청을 취소하시겠어요?", type: "CONTRACT_CANCEL" });
              }}
            />
          }
        />
      )}
      {/* 확인 취소 버튼 있는 확인 모달 */}
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle={"취소"}
        btnRightTitle={"확인"}
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        {confirmModal.message || ""}
      </ConfirmModal>
      {/* 확인 버튼 있는 확인 모달 */}
      <ConfirmModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => {
          setAlertModal({ isOpen: false, message: "" });
        }}
      >
        {alertModal.message || ""}
      </ConfirmModal>
    </section>
  );
};

export default BasicInfo;
