import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import {
  ContractAccessLog,
  EmailVerificationResponseData,
  GetContractAccessLogParams,
  MemberData,
  MemberUpdateResponseData,
  SmsCodeVerificationResponseData,
  SmsVerificationResponseData,
} from "./member-types";

/**
 * TPB01. 회원 가입
 */
export async function patchJoinAsync(
  axios: AxiosInstance,
  params?: {
    email: string; // 가입 이메일
    marketingConsent?: boolean; // 마케팅 동의 여부
  },
): Promise<AxiosResponse<ApiResponse<MemberUpdateResponseData>>> {
  if (!params?.email) {
    throw Error("email is not found.");
  }
  return await axios.patch("/api/court/mb/userinfo/join", {
    email: params.email,
    marketingConsent: params.marketingConsent,
  });
}

/* 
  TPB04. 내 프로필 조회
*/
export async function getMemberMeAsync(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<MemberData>>> {
  return await axios.get<ApiResponse<MemberData>>("/api/court/mb/me");
}

/**
 * TPB05. 마케팅 수신 동의 수정 처리
 */
export async function patchMarketingConsentAsync(
  axios: AxiosInstance,
  params?: {
    marketingConsent: boolean; // 마케팅 수신 동의 여부
  },
): Promise<AxiosResponse<ApiResponse<MemberUpdateResponseData>>> {
  return await axios.patch("/api/court/mb/me/marketing-consent", {
    marketingConsent: !!params?.marketingConsent,
  });
}

/**
 * TPB06. SMS 인증 코드 발송
 */
export async function postSmsVerificationsAsync(
  axios: AxiosInstance,
  params?: {
    phoneNumber: string; // 점유 인증 대상 휴대폰 번호 (010-1234-1234)
    state?: string; // SMS 인증 처리 문맥 상태 식별 정보
  },
): Promise<AxiosResponse<ApiResponse<SmsVerificationResponseData>>> {
  if (!params?.phoneNumber) {
    throw Error("phoneNumber is not found.");
  }
  return await axios.post("/api/court/mb/sms/verifications", {
    phoneNumber: params.phoneNumber,
    state: params?.state,
  });
}

/**
 * TPB07. 휴대폰 번호 변경 처리
 */
export async function patchMemberPhoneNumberAsync(
  axios: AxiosInstance,
  params?: {
    state: string; // SMS 인증 처리 문맥 상태 식별 정보
    phoneNumber: string; // 점유 인증 대상 휴대폰 번호
    smsCode: string; // SMS 인증 코드
  },
): Promise<AxiosResponse<ApiResponse<MemberUpdateResponseData>>> {
  if (!params?.state) {
    throw Error("state is not found.");
  }
  if (!params?.phoneNumber) {
    throw Error("phoneNumber is not found.");
  }
  if (!params?.smsCode) {
    throw Error("smsCode is not found.");
  }
  return await axios.patch("/api/court/mb/me/phone-number", {
    state: params.state,
    phoneNumber: params.phoneNumber,
    smsCode: params?.smsCode,
  });
}

/**
 * TPB08. 이메일 인증 링크 발송
 */
export async function postEmailVerificationsAsync(
  axios: AxiosInstance,
  params?: {
    email: string; // 인증 대상 이메일 주소
  },
): Promise<AxiosResponse<ApiResponse<EmailVerificationResponseData>>> {
  if (!params?.email) {
    throw Error("email is not found.");
  }
  return await axios.post("/api/court/mb/email/verifications", {
    email: params.email,
    type: "COURT", // court 에서 이메일 발송
  });
}

/**
 * TPB09. SMS 인증 코드 검증 조회
 */
export async function getSmsVerificationsAsync(
  axios: AxiosInstance,
  params?: {
    state: string; // SMS 인증 처리 문맥 상태 식별 정보
    phoneNumber: string; // 인증 대상 휴대폰 번호
    smsCode: string; // SMS 인증 코드
  },
): Promise<AxiosResponse<ApiResponse<SmsCodeVerificationResponseData>>> {
  if (!params?.state) {
    throw Error("state is not found.");
  }
  if (!params?.phoneNumber) {
    throw Error("phoneNumber is not found.");
  }
  if (!params?.smsCode) {
    throw Error("smsCode is not found.");
  }
  return await axios.get(`/api/court/mb/sms/verifications/${params.state}`, {
    params: {
      phoneNumber: params.phoneNumber,
      smsCode: params.smsCode,
    },
  });
}

// TAC21. 계약에 대한 출입로그 조회
export async function getContractAccessLogs(
  axios: AxiosInstance,
  params?: GetContractAccessLogParams,
): Promise<AxiosResponse<ApiResponse<{ content: ContractAccessLog[] }>>> {
  if (!params?.contractManageId) throw Error("contractManageId is not found.");

  const property = params.pagination?.property?.reduce((acc, item, index) => {
    return {
      ...acc,
      [`sort.orders[${index}].property`]: item,
    };
  }, {});

  const direction = params.pagination?.direction?.reduce((acc, item, index) => {
    return {
      ...acc,
      [`sort.orders[${index}].direction`]: item,
    };
  }, {});

  return await axios.get(`/api/court/ct/access/contract-manage/${params.contractManageId}/log`, {
    params: {
      page: params?.pagination?.page,
      size: params.pagination?.size,
      ...property,
      ...direction,
      ...params.accessLogParams,
    },
    paramsSerializer: (params) => {
      const removedParams = Object.keys(params).reduce((acc, key) => {
        if (params[key] === undefined) return acc;
        return {
          ...acc,
          [key]: params[key],
        };
      }, {});

      return new URLSearchParams(removedParams).toString();
    },
  });
}

/**
 * TMBV201. 탈퇴 요청 처리
 */
export async function postWithdrawalAsync(axios: AxiosInstance): Promise<AxiosResponse<ApiResponse<any>>> {
  return await axios.post("/api/court/mb/me/withdrawal");
}
