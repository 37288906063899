import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import img from "src/assets/images/icons_complete.svg";
import courtAuthStateRepository from "src/components/court-auth/utils/court-auth-state-repository";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useMemberInfoState } from "src/recoil/member/hook";

/**
 * 회원가입 완료 화면
 */
const JoinComplete = () => {
  const navigate = useNavigate();

  const { member } = useMemberInfoState();

  const returnUri = useMemo(() => {
    const returnToLatest = courtAuthStateRepository.load()?.returnTo?.latest;

    return returnToLatest?.uri.includes("quotation") ? returnToLatest.uri.replace("/front", "") : "";
  }, []);
  return (
    <>
      <MetaTag title="회원가입" />

      <Header rightDisabled={true} />
      <div className="my-page">
        <article className="register">
          <div className="title-wrap">
            <h1>
              반가워요 :) <br />
              <b>{(member?.email || "").split("@")[0] || ""}</b> 님
            </h1>
          </div>
          <section className="contents">
            <img src={img} alt="회원가입 완료" />
            <p>회원 가입이 정상적으로 완료되었습니다.</p>
          </section>
          <button
            type="button"
            className="login-btn"
            onClick={() => {
              returnUri !== "" ? navigate(`${returnUri}`) : navigate("/court/");
            }}
          >
            완료
          </button>
        </article>
      </div>
    </>
  );
};
export default JoinComplete;
