import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { patchMarketingConsentAsync } from "src/api/member/member-api";
import { MbMemberState } from "src/api/member/member-types";
import { ConfirmModal } from "src/components";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useMemberInfoState } from "src/recoil/member/hook";
import { convertKoreanPhoneNumber } from "src/utils/common-util";

type MarketingModal = {
  isOpen: boolean;
  isMarketingConsent?: boolean;
  updateDate?: string;
};

/**
 * 마이페이지 메인 > 프로필 편집 화면
 */
const ProfileEdit = () => {
  const navigate = useNavigate();

  const { member, logout } = useMemberInfoState();

  // 마케팅 수신 동의 수정 처리 api
  const { executeAsync: patchMarketingConsent } = useApiOperation(patchMarketingConsentAsync);

  // 마케팅 수신 동의 여부
  const [isMarketingConsent, setIsMarketingConsent] = useState<boolean>(false);

  // 이메일 인증 이유 안내 모달
  const [isOpenModalEmailVerify, setIsOpenModalEmailVerify] = useState<boolean>(false);

  // 로그아웃 확인 모달
  const [isOpenModalLogout, setIsOpenModalLogout] = useState<boolean>(false);

  // 회원탈퇴 모달
  const [isOpenModalWithdraw, setIsOpenModalWithdraw] = useState<boolean>(false);

  // 마케팅 수산 동의 여부 수정 완료 모달
  const [marketingModal, setMarketingModal] = useState<MarketingModal>({ isOpen: false });

  // 마케팅 수신 동의 수정 처리
  const editMarketingConsent = useCallback(
    async (isMarketingConsent: boolean) => {
      const { data } = await patchMarketingConsent({ marketingConsent: isMarketingConsent });
      if (data?.data?.member) {
        setIsMarketingConsent(isMarketingConsent);

        // 마케팅 수신 동의 상태
        const marketingConsentState = (data?.data?.member?.states || []).find(
          (state: MbMemberState) => state.status === (isMarketingConsent ? "MARKETING_CONSENT" : "MARKETING_CONSENT_DISAGREE"),
        );
        // 마케팅 수신 동의 시간
        const updateDate = marketingConsentState?.statusDate
          ? moment(marketingConsentState?.statusDate).format("YYYY/MM/DD HH:mm")
          : moment().format("YYYY/MM/DD HH:mm");
        setMarketingModal({ isOpen: true, isMarketingConsent, updateDate });
      }
    },
    [patchMarketingConsent],
  );

  useEffect(() => {
    if ((member?.states || []).length > 0) {
      // 회원 마케팅 동의 여부
      const _isMarketingConsent = !!(member?.states || []).find((state: MbMemberState) => state.status === "MARKETING_CONSENT");
      setIsMarketingConsent(_isMarketingConsent);
    }
  }, [member]);

  // 회원 이메인 인증 여부
  const isEmailVerified = useMemo(() => {
    if (!member) return false;
    return !!(member?.states || []).find((state: MbMemberState) => state.status === "EMAIL_VERIFIED");
  }, [member]);

  return (
    <>
      <MetaTag title="마이페이지" />
      <Header />
      <div className="my-page">
        <article className="profile-edit">
          <section className="profile-edit__top">
            <h1>프로필 편집</h1>
            <div className="flex-center-center py50">
              <div className="flex-center">
                <div className="profile-img">
                  <span>{member?.email && member?.email.charAt(0).toLocaleUpperCase()}</span>
                </div>
              </div>
            </div>

            {/* 이메일 인증 */}
            <div className="base-input-wrap">
              <input type="text" className="base-input" value={member?.email || ""} maxLength={200} readOnly />
              {/* 이메일 인증 여부 */}
              {isEmailVerified ? (
                // 인증 완료 버튼
                <button type="button" className="check-green-only" disabled></button>
              ) : (
                <button
                  type="button"
                  className="only-text text-primary3"
                  onClick={() => {
                    // 이메일 인증 화면으로 이동
                    navigate("/court/mypage/profile/email");
                  }}
                >
                  인증하기
                </button>
              )}
              {/* 이메일 인증 안되어있을 경우 */}
              {!isEmailVerified && (
                <div className="validation-wrap">
                  <div className="validation-wrap__left">
                    <p
                      className="validation-text ic-info"
                      onClick={() => {
                        setIsOpenModalEmailVerify(true);
                      }}
                    >
                      계정보호를 위해 이메일을 인증해주세요.
                    </p>
                  </div>
                  <div className="validation-wrap__right"></div>
                </div>
              )}
            </div>

            {/* 휴대폰 변경 */}
            <div className="base-input-wrap mt24">
              <input
                type="text"
                className="base-input"
                maxLength={200}
                value={member?.phoneNumber ? convertKoreanPhoneNumber(member.phoneNumber) : ""}
                readOnly
              />
              <button
                type="button"
                className="only-text text-primary3"
                onClick={() => {
                  // 휴대폰 변경 화면으로 이동
                  navigate("/court/mypage/profile/phone");
                }}
              >
                변경
              </button>
            </div>
            <div className="flex-start-between mt50">
              <div className="marketing-text">
                <span>마케팅 정보 수신에 동의합니다</span>
                <p>혜택과 이벤트 정보를 빠르게 받아보세요.</p>
              </div>
              <label className={`base-toggle`}>
                <input
                  id="toggle"
                  type="checkbox"
                  name="test"
                  checked={isMarketingConsent}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // 마케팅 수신 동의 여부 수정
                    editMarketingConsent(!!e.target.checked);
                  }}
                />
                <div className="base-toggle__slider"></div>
              </label>
            </div>
          </section>
          <section className="profile-edit__bottom">
            <ul>
              <li className="">
                <button type="button" onClick={() => navigate("/court/mypage/profile/withdrawal")}>
                  회원탈퇴
                </button>
              </li>
              <li className="">
                <button type="button" onClick={() => setIsOpenModalLogout(true)}>
                  로그아웃
                </button>
              </li>
            </ul>
          </section>
        </article>

        {/* 이메일 인증 이유 안내 모달 */}
        <ConfirmModal
          isOpen={isOpenModalEmailVerify}
          btnRightTitle="확인"
          onClick={() => {
            setIsOpenModalEmailVerify(false);
          }}
          className="px20 pb20"
          children={
            <div className="email-auth-info-modal">
              <h1>이메일 인증을 왜 해야 하나요?</h1>
              <span>&#x1F510; 계정 찾기 및 보호</span>
              <br />
              <br />
              <ul>
                <li>휴대폰 기기나 번호를 변경했을 때</li>
                <li>휴면 회원으로 전환 되었을 때</li>
                <li>계정을 분실 했을 때 등</li>
              </ul>
              <br />
              <p>위와 같은 상황에서 이메일을 통해 사용자 정보를 확인하고 안전하게 계정을 관리할 수 있도록 이메일 인증을 꼭 진행해주세요.</p>
            </div>
          }
        />

        {/* 마케팅 수산 동의 여부 수정 완료 모달 */}
        <ConfirmModal
          isOpen={marketingModal.isOpen}
          btnRightTitle="확인"
          onClick={() => {
            setMarketingModal({ isOpen: false });
          }}
          children={
            <div>
              <p className="font18 font-weight-600">{marketingModal.isMarketingConsent ? "마케팅 수신 동의" : "마케팅 수신 거부"}</p>
              <p className="font15 font-weight-400 mt20">정상적으로 처리되었습니다.</p>
              <p className="font15 font-weight-400 mt5">처리 일시 : {marketingModal.updateDate}</p>
            </div>
          }
        />

        {/* 로그아웃 확인 모달 */}
        <ConfirmModal
          isOpen={isOpenModalLogout}
          btnLeftTitle="아니오"
          btnRightTitle="네"
          onClose={() => setIsOpenModalLogout(false)}
          onClick={() => logout()}
          children={<p className="font18">로그아웃 하시겠어요?</p>}
        />

        {/* 회원탈퇴 모달 */}
        <ConfirmModal
          isOpen={isOpenModalWithdraw}
          btnRightTitle="확인"
          onClick={() => setIsOpenModalWithdraw(false)}
          children={<p className="font18">고객센터에 문의해주세요.</p>}
        />
      </div>
    </>
  );
};
export default ProfileEdit;
